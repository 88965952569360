import { DiscountBatchUpdateProduct } from '../entity/DiscountBatchUpdateProduct';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../entity/Product';
import { environment } from '../../environments/environment';

@Injectable()
export class BatchUpdateProductService {

  private readonly productsForBatchUpdate = new Set();
  private readonly unSelectProducts = new Set();
  public productCount = 0;
  public isAllProductSelected = false;

  constructor(private readonly httpClient: HttpClient) { }

  public selectProduct(product: Product): void {
    this.productsForBatchUpdate.add(product.id);
    this.unSelectProducts.delete(product.id);
  }

  public unSelectProduct(product: Product): void {
    this.unSelectProducts.add(product.id);
    this.productsForBatchUpdate.delete(product.id);
  }

  public compareProductLengthWithUnselectProductCount(): boolean {
    return this.productCount === this.unSelectProducts.size;
  }

  public isProductsForBatchUpdateEmpty(): boolean {
    return !this.productsForBatchUpdate.size;
  }

  public hasProductInCollections(product: Product): boolean {
    return this.isAllProductSelected ? !this.unSelectProducts.has(product.id) : this.productsForBatchUpdate.has(product.id);
  }

  public clearProductsForBatchUpdate(): void {
    this.productsForBatchUpdate.clear();
    this.unSelectProducts.clear();
  }

  public batchUpdateProduct(discountId?: string, discountStartDate?: Date): Observable<unknown> {
    const productsId = Array.from(this.productsForBatchUpdate) as string[];
    const unselectedProductsId = Array.from(this.unSelectProducts) as string[];
    const discountBatchUpdateProduct: DiscountBatchUpdateProduct = {
      discountId,
      productsId,
      isAllProductSelected: this.isAllProductSelected,
      unselectedProductsId,
      discountStartDate: discountStartDate?.toString(),
    };

    return this.batchUpdateProcess(discountBatchUpdateProduct);
  }

  private batchUpdateProcess(discountBatchUpdateProduct: DiscountBatchUpdateProduct): Observable<unknown> {
    const url = environment.API_URL.concat('/product/batch/discounts');
    this.clearProductsForBatchUpdate();

    return this.httpClient.put(url, discountBatchUpdateProduct, { headers: new HttpHeaders() });
  }

}
