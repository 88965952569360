import { BatchUpdateProductService } from '../../services/batch-update-product.service';
import { CartService } from '../../services/cart.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { DiscountService } from '../../services/discount.service';
import { ImageGalleryModalComponent } from '../../modals/image-gallery-modal/image-gallery-modal.component';
import { LoadingService } from '../loading/loading.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Product } from '../../entity/Product';
import { ProductService } from '../../services/product.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { throttle } from '../../decorators/throttle.decorator';
import { throttleDelayInMilliseconds } from 'src/app/constants/constants';

@Component({
  selector: 'app-product',
  templateUrl: './product.html',
  styleUrls: ['./product.sass']
})
export class ProductComponent implements OnInit {

  @Input()
  public product!: Product;
  @Input()
  public isUserAdmin?: boolean;
  @Input()
  public isActionsHidden = false;
  @Input()
  public isBatch = false;
  @Input()
  public isAddToBatchUpdate!: boolean;
  @Input()
  public isImagesViewEnabled = false;
  @Input()
  public isImageDeletingAllowed = false;
  @Input()
  public isEditButtonVisible = true;
  @Input()
  public isCartButtonVisible = true;
  @Input()
  public isRemoveButtonVisible = true;
  @Input()
  public isCartButtonReadonly = false;
  @Input()
  public isLikeButtonReadonly = false;

  @Output()
  public deleteProduct = new EventEmitter<Product>();
  @Output()
  public deleteProductImage = new EventEmitter<string>();

  public isLiked = false;
  public isImageLoaded = false;

  constructor(
    public readonly discountService: DiscountService,
    public readonly cartService: CartService,
    private readonly productService: ProductService,
    private readonly snackBarService: MatSnackBar,
    private readonly matDialog: MatDialog,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly userService: UserService,
    private readonly batchUpdateService: BatchUpdateProductService,
  ) { }

  public ngOnInit(): void {
    this.isLiked = this.userService.user
      ? this.productService.isUserLikedProduct(this.product, this.userService.user)
      : false;

    this.isAddToBatchUpdate = this.batchUpdateService.hasProductInCollections(this.product);
    this.discountInit();
  }

  public discountInit(): void {
    const productDiscount = this.product.productDiscount;
    if (productDiscount.discount) {
      productDiscount.discount.isActive = this.discountService.getIsDiscountEnabled(productDiscount) || Boolean(this.isUserAdmin);
    }
  }

  public editProduct(product: Product): void {
    this.loadingService.showSpinner();
    this.router.navigate([`/admin/editProduct/${product.id}`]);
  }

  public removeProduct(id: string): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent);

    dialogRef.afterClosed().subscribe((userConfirmAction) => {
      if (userConfirmAction) {
        this.loadingService.showSpinner();
        this.productService.removeProduct(id).subscribe({
          next: () => {
            this.snackBarService.open('Successful remove product', undefined, { duration: 5000 });
            this.deleteProduct.emit(this.product);
            this.cartService.removeFromCart(this.product);
          },
          error: (error) => console.error(error),
          complete: () => this.loadingService.hideSpinner(),
        });
      }
    });
  }

  public toggleBatchUpdate(event: MatCheckboxChange): void {
    if (event.checked) {
      this.batchUpdateService.selectProduct(this.product);

      return;
    }

    this.batchUpdateService.unSelectProduct(this.product);
    if (this.batchUpdateService.compareProductLengthWithUnselectProductCount()) {
      this.batchUpdateService.isAllProductSelected = false;
      this.batchUpdateService.clearProductsForBatchUpdate();
    }
  }

  public enableProduct(id: string): void {
    this.loadingService.showSpinner();
    this.productService.enableProduct(id).subscribe({
      next: () => {
        this.snackBarService.open('Successful enable product', undefined, { duration: 5000 });
        this.product.isActive = true;
      },
      error: (error) => console.error(error),
      complete: () => this.loadingService.hideSpinner(),
    });
  }

  public disableProduct(id: string): void {
    this.loadingService.showSpinner();
    this.productService.disableProduct(id).subscribe({
      next: () => {
        this.snackBarService.open('Successful disable product', undefined, { duration: 5000 });
        this.product.isActive = false;
      },
      error: (error) => console.error(error),
      complete: () => this.loadingService.hideSpinner(),
    });
  }

  @throttle(throttleDelayInMilliseconds)
  public likeProduct(id: string): void {
    this.toggleProductLike();
    this.productService.likeProduct(id).subscribe({
      error: () => this.toggleProductLike(),
    });
  }

  public onImageClick(): void {
    this.matDialog.open(ImageGalleryModalComponent, {
      data: this.product,
      maxWidth: '100vw',
      width: '100%',
      height: '100%',
    });
  }

  public onImageLoaded(): void {
    this.isImageLoaded = true;
  }

  public getPrimaryImageSrc(): string {
    const defaultImageScr = '../../assets/default-product.png';
    const isImageExists = this.product.images.length;

    if (!isImageExists) return defaultImageScr;

    return this.product.images[0].url;
  }

  public onRemoveImage(imageId: string): void {
    this.snackBarService.open('Successful remove image', undefined, { duration: 5000 });
    this.deleteProductImage.emit(imageId);
  }

  public isProductSold(): boolean {
    return this.product.quantity < 1;
  }

  private toggleProductLike(): void {
    const productLikeId = this.userService.user!.id;

    this.isLiked = !this.isLiked;
    this.isLiked
      ? this.product.likes.push({ id: productLikeId })
      : this.product.likes = this.product.likes.filter((productLike) => productLike.id !== productLikeId);
  }

}

