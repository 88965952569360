import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../components/loading/loading.service';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'app-home',
  templateUrl: './order-history.html',
  styleUrls: ['./order-history.sass']
})
export class OrderHistoryComponent implements OnInit {

  public silver: number | null = null;
  public gold: number | null = null;

  constructor(
        private readonly walletService: WalletService,
        private readonly loadingService: LoadingService,
  ) { }

  public ngOnInit(): void {
    this.fetchCoinsCount();
  }

  public fetchCoinsCount(): void {
    this.walletService.getInfo()
      .subscribe({
        next: (res) => {
          this.silver = res.silver;
          this.gold = res.gold;
        },
        complete: () => this.loadingService.hideSpinner(),
      });
  }
  
}

