import { Component, OnInit } from '@angular/core';
import { CreateRewardGroup } from 'src/app/entity/RewardGroup';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-reward-group-modal',
  templateUrl: './add-reward-group-modal.html',
  styleUrls: ['./add-reward-group-modal.sass']
})
export class AddRewardGroupModalComponent implements OnInit {

  public form!: UntypedFormGroup;

  constructor(
    private readonly dialogRef: MatDialogRef<AddRewardGroupModalComponent>,
    private readonly formBuilder: UntypedFormBuilder
  ) { }

  public ngOnInit(): void {
    this.form = this.getForm();
  }

  public handleFormSubmit(formValue: CreateRewardGroup): void {
    this.dialogRef.close(formValue);
  }

  private getForm(): UntypedFormGroup {
    return this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
        ],
      ],
      gold: [
        null,
        [
          Validators.min(0),
          Validators.max(3000),
          Validators.required,
        ]
      ],
      silver: [
        null,
        [
          Validators.min(0),
          Validators.max(3000),
          Validators.required,
        ]
      ],
      comment: [
        null,
        [
          Validators.minLength(1),
          Validators.maxLength(250),
          Validators.required,
        ]
      ],
      day: [
        null,
        [
          Validators.min(1),
          Validators.max(31),
          Validators.required,
        ],
      ],
      month: [
        null,
        [
          Validators.min(1),
          Validators.max(12),
          Validators.required,
        ],
      ],
      isIncludeAllUsers: [
        false,
        [
          Validators.required,
        ],
      ],
    });
  }

}