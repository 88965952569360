import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { KeycloakProfile } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';
import { RoleEnum } from '../enums/RoleEnum';
import { StorageService } from './storage.service';
import { User } from '../entity/User';
import { map } from 'rxjs/operators';

type Profile = KeycloakProfile & {
  attributes?: {
    picture: string[];
  };
};

@Injectable()
export class UserService {

  private readonly userStorageKey = 'user';
  private readonly user$ = new BehaviorSubject<User | null>(null);

  constructor(
    private readonly storageService: StorageService,
    private readonly keycloak: KeycloakService,
  ) {
    this.restoreUserFromStorage();
  }

  public get user(): User | null {
    return this.user$.value;
  }

  public set user(user: User | null) {
    const keycloakImageUrl = this.extractImageUrlFromKeycloakProfile();

    if(user) user.imageUrl = keycloakImageUrl;

    this.user$.next(user);
    this.storageService.set(this.userStorageKey, user);
  }

  public isAdmin(): boolean {
    return this.keycloak.isUserInRole(RoleEnum.ADMIN);
  }

  public getImageUrl$(): Observable<string> {
    return this.user$.pipe(
      map(user => user?.imageUrl || ''),
    );
  }

  private restoreUserFromStorage(): void {
    this.user$.next(this.storageService.get(this.userStorageKey));
  }

  private extractImageUrlFromKeycloakProfile(): string {
    const keycloakInstance = this.keycloak.getKeycloakInstance();

    if (!keycloakInstance) return '';

    const profile = keycloakInstance.profile as Profile;

    return profile?.attributes?.picture[0] || '';
  }

}
