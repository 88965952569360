<h1 mat-dialog-title>Add new group</h1>
<mat-dialog-content>
    <form
        [formGroup]="form"
        id="addRewardGroupModal"
        (ngSubmit)="handleFormSubmit(form.value)">
        <div class="wrapper">
            <mat-form-field>
                <mat-label>Group Name</mat-label>
                <input formControlName="name" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Silver</mat-label>
                <input type="number" formControlName="silver" min="0" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Gold</mat-label>
                <input type="number" formControlName="gold" min="0" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Comment</mat-label>
                <input formControlName="comment" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Reward Day</mat-label>
                <input type="number" formControlName="day" min="0" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Reward Month</mat-label>
                <input type="number" formControlName="month" min="0" matInput>
            </mat-form-field>
            <mat-checkbox formControlName="isIncludeAllUsers">
                The group includes all users
            </mat-checkbox>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button mat-raised-button color="accent" mat-dialog-close>Cancel</button>
  <button form="addRewardGroupModal"
          [disabled]="form.invalid"
          mat-button
          mat-raised-button
          color="accent"
          type="submit">
          Save
  </button>
</mat-dialog-actions>