import { Cart } from '../interfaces/cart';
import { DiscountService } from './discount.service';
import { EventEmitter, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderService } from './order.service';
import { Product } from '../entity/Product';
import { ProductDiscount } from '../entity/ProductDiscount';
import { ProductService } from './product.service';
import { ProductSummary } from '../entity/ProductSummary';
import { StorageService } from './storage.service';
import { UserService } from './user.service';
import { WalletService } from './wallet.service';

@Injectable()
export class CartService {

  private _cart!: Cart;
  public totalCartPrice = 0;
  public onOrderSubmit: EventEmitter<unknown> = new EventEmitter();

  constructor(
    private readonly storageService: StorageService,
    private readonly userService: UserService,
    private readonly orderService: OrderService,
    private readonly snackBarService: MatSnackBar,
    private readonly walletService: WalletService,
    private readonly discountService: DiscountService,
    private readonly productService: ProductService,
  ) { }

  public get cart(): Cart {
    if (this._cart) return this._cart;

    this.initCart();

    return this._cart;
  }

  public set cart(value: Cart) {
    this._cart = value;
    this.storageService.set('cart', this._cart);
  }

  public initCart(): void {
    const storedCart = <Cart>this.storageService.get('cart');
    const currentUser = this.userService.user;
    if (storedCart && currentUser?.id === storedCart.userId) {
      this.cart = storedCart;
    } else {
      this.cart = {
        items: [],
        userId: currentUser?.id,
        comment: ''
      };
    }
    this.updateTotalCartPrice();
  }

  public reloadCartProducts(): void {
    for (const item of this.cart.items) {
      this.productService.getProductSummary(item.product.id)
        .subscribe((product) => {
          const localItem = this.cart.items.find(i => i.product.id === product.id);
          if (localItem) {
            localItem.product = product;
          }
          this.updateTotalCartPrice();
          this.backupCart();
        });
    }
  }

  public addToCart(product: Product): void {
    const item = this.cart.items.find(i => i.product.id === product.id);

    if (item) {
      item.quantity += 1;
    } else {
      const productCopy: ProductSummary = Object.assign({}, { ...product });

      if (!this.discountService.getIsDiscountPublished(product.productDiscount)) {
        productCopy.productDiscount = {} as ProductDiscount;
      }

      this.cart.items.push({ product: productCopy, quantity: 1 });
    }

    this.updateTotalCartPrice();
    this.backupCart();
  }

  public removeFromCart(product: ProductSummary): void {
    this.cart.items = this._cart.items.filter(item => item.product.id !== product.id);
    this.updateTotalCartPrice();
    this.backupCart();
  }

  public clearCart(): void {
    this.storageService.remove('cart');
    this.initCart();
  }

  public isCartEmpty(): boolean {
    return this.cart.items.length === 0;
  }

  public isProductInCart(product: Product): boolean {
    return this.cart.items.some(item => item.product.id === product.id);
  }

  public isMoneyEnough(): boolean {
    return this.totalCartPrice <= this.walletService.silver;
  }

  public getItemsCount(): number {
    return this.cart.items.length;
  }

  public getProductsCount(): number {
    return this.cart.items.reduce((acc, item) => acc + item.quantity, 0);
  }

  public updateTotalCartPrice(): void {
    this.totalCartPrice = this.getProductsPrice();
  }

  public makeOrder(): void {
    if (!this.cart.items.length) {
      return;
    }

    this.orderService.createOrder({
      items: this.cart.items.map(item => ({ productId: item.product.id, quantity: item.quantity })),
      comment: this.cart.comment.trim()
    }).subscribe(() => {
      this.snackBarService.open('Order created successfully', undefined, { duration: 5000 });
      this.clearCart();
      this.onOrderSubmit.emit();
    });
  }

  private backupCart(): void {
    this.storageService.set('cart', this._cart);
  }

  private getProductsPrice(): number {
    let result = 0;
    this.cart.items.forEach(item => {
      const product = item.product;
      const priceWithDiscount = this.discountService.getPriceWithDiscount(product.price, product.productDiscount);
      result += priceWithDiscount * item.quantity;
    });

    return result;
  }

}
