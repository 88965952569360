import { CoinsAdding } from '../entity/UserCoinAudit';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable()
export class WalletService {

  public silver = 0;
  public gold = 0;

  public onCoinsAdd: EventEmitter<unknown> = new EventEmitter();
  public onCoinsTransfer: EventEmitter<unknown> = new EventEmitter();

  constructor(private readonly httpClient: HttpClient) { }

  public getInfo(): Observable<{ silver: number, gold: number }> {
    const url = environment.API_URL.concat('/wallet');

    return this.httpClient.get<{ silver: number, gold: number }>(url, { headers: new HttpHeaders() })
      .pipe(
        tap(res => this.silver = res.silver),
        tap(res => this.gold = res.gold),
      );
  }

  public transferCoins(params: { userId: string, comment: string, coins: number, anonymously: boolean }): Observable<void> {
    const url = environment.API_URL.concat('/wallet/coin/transfer');

    return this.httpClient.post<void>(url, params, { headers: new HttpHeaders() })
      .pipe(
        tap(() => this.onCoinsTransfer.emit())
      );
  }

  public addCoins(params: { comment: string, coins: CoinsAdding[] }): Observable<void> {
    const url = environment.API_URL.concat('/wallet/coin/add');

    return this.httpClient.post<void>(url, params, { headers: new HttpHeaders() })
      .pipe(
        tap(() => this.onCoinsAdd.emit())
      );
  }

}
