import { BatchUpdateProductService } from '../../services/batch-update-product.service';
import { Component, Inject, Input, ViewChild } from '@angular/core';
import { Discount } from '../../entity/Discount';
import { EmailService } from '../../services/email.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductComponent } from '../../components/product/product.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-batch-update-product',
  templateUrl: './batch-update-modal.html',
})
export class BatchUpdateModalComponent {

  @ViewChild('formElement')
  public formElement?: HTMLFormElement;
  @Input()
  public productComponent?: ProductComponent;

  public productForm: UntypedFormGroup;
  public minDate = new Date();
  public isPublicationDelayEnabled = false;
  public modalData = this.data;

  private isEmailNotification!: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { discounts: Discount[], isSelect: boolean },
    private readonly formBuilder: UntypedFormBuilder,
    private readonly snackBarService: MatSnackBar,
    public readonly batchUpdateService: BatchUpdateProductService,
    public readonly dialogRef: MatDialogRef<BatchUpdateModalComponent>,
    private readonly emailService: EmailService,
  ) {
    this.productForm = this.formBuilder.group({
      discount: [undefined],
      discountStartDate: [
        { value: undefined, disabled: true }
      ]
    });
  }

  public onBatchUpdateSubmit(): void {
    const discount = this.productForm.get('discount')?.value;
    const startDate = this.productForm.get('discountStartDate')?.value;
    if (this.isEmailNotification && discount) {
      this.emailService.sendNewDiscountEmail()
        .subscribe(() => {
          this.snackBarService.open('Emails sent', undefined, { duration: 5000 });
        });
    }
    this.batchUpdateService.batchUpdateProduct(discount?.id, startDate).subscribe(() => {
      this.snackBarService.open('Batch update was success', undefined, { duration: 5000 });
      this.closeModal(true);
    });
  }

  public toggleEmail(event: MatCheckboxChange): void {
    this.isEmailNotification = event.checked;
  }

  public closeModal(isConfirmed: boolean): void {
    this.dialogRef.close(isConfirmed);
  }

  public compareDiscounts(discount1: Discount, discount2: Discount): boolean {
    if (!discount1 || !discount2) {
      return false;
    }

    return discount1.id === discount2.id;
  }

  public disableDatepicker(): void {
    this.isPublicationDelayEnabled = !this.isPublicationDelayEnabled;
    if (this.isPublicationDelayEnabled) {
      this.productForm.get('discountStartDate')?.enable();
    } else {
      this.productForm.get('discountStartDate')?.disable();
    }
  }

}
