import { EventEmitter, Injectable } from '@angular/core';
import { User } from '../entity/User';

@Injectable()
export class GlobalStateService {

  public userUpdate = {
    update: (newUser: User) => this.userUpdate.onUpdate.emit(newUser),
    onUpdate: new EventEmitter(true)
  };

}
