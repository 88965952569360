import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BatchUpdateModalComponent } from '../../modals/batch-update-modal/batch-update-modal.component';
import { BatchUpdateProductService } from '../../services/batch-update-product.service';
import { CartService } from '../../services/cart.service';
import { Discount } from '../../entity/Discount';
import { LoadingService } from '../loading/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Product } from '../../entity/Product';
import { ProductService } from '../../services/product.service';
import { Router } from '@angular/router';
import { SortTypeEnum } from '../../enums/SortTypeEnum';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-products',
  templateUrl: './products.html',
  styleUrls: ['./products.sass']
})
export class ProductsComponent implements OnInit, AfterViewInit {

  @Input()
  public isImagesViewEnabled = false;

  @ViewChild('searchFieldElement')
  private readonly searchFieldElement!: ElementRef;
  @ViewChild('paginator')
  private readonly paginator!: MatPaginator;

  public isAdminView = false;
  public products: Product[] = [];
  public discounts: Discount[] = [];
  public isLoaded = false;
  public sortTypeSelected = 'name,asc';
  public sortTypes = SortTypeEnum;
  public searchField = '';
  public length = 100;
  public pageSize = 10;

  public readonly pageSizeOptions = [10, 20, 50, 100];
  private readonly delayForSearchingInMilliseconds = 1000;

  constructor(
    public readonly batchUpdateService: BatchUpdateProductService,
    private readonly productService: ProductService,
    private readonly snackBarService: MatSnackBar,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly modalService: MatDialog,
    private readonly cartService: CartService,
  ) {
    this.isAdminView = router.isActive('admin', false);
    this.cartService.onOrderSubmit.subscribe(() => this.ngOnInit());
  }

  public ngOnInit(): void {
    this.batchUpdateService.isAllProductSelected = false;
    this.fetchProducts();
  }

  public ngAfterViewInit(): void {
    this.loadingService.showSpinner();
    fromEvent(this.searchFieldElement.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(this.delayForSearchingInMilliseconds),
        distinctUntilChanged(),
        tap(() => {
          this.fetchProducts();
        })
      )
      .subscribe();
  }

  public fetchProducts(
    query = this.searchField || '',
    pageSize = this.paginator?.pageSize || this.pageSize,
    page = this.paginator?.pageIndex || 0,
    sortTypeSelected = this.sortTypeSelected,
  ): void {
    this.loadingService.showSpinner();
    this.isLoaded = false;
    if (this.isAdminView) {
      this.productService.getProducts(query, pageSize, page, sortTypeSelected).subscribe({
        next: (products) => {
          this.products = products.data;
          this.length = products.totalCount;
          this.batchUpdateService.productCount = products.totalCount;
          this.isLoaded = true;
        },
        error: (error) => {
          console.error(error);
          this.isLoaded = true;
        },
      });
      this.productService.getDiscounts().subscribe({
        next: (discounts) => this.discounts = discounts.data,
        error: (error) => console.error(error),
        complete: () => this.loadingService.hideSpinner(),
      });
    } else {
      this.productService.getProductsSummary(query, pageSize, page, sortTypeSelected).subscribe({
        next: (products) => {
          this.products = products.data;
          this.products.forEach(product => product.isActive = true);
          this.length = products.totalCount;
          this.isLoaded = true;
        },
        error: (error) => {
          console.error(error);
          this.isLoaded = true;
        },
        complete: () => this.loadingService.hideSpinner(),
      });
    }
  }

  public addedNewProduct(product: Product): void {
    this.products = [product].concat(this.products); // Put at the start
  }

  public openBatchUpdateModal(): void {
    const dialogRef = this.modalService.open(BatchUpdateModalComponent, {
      width: '350px',
      data: { discounts: this.discounts }
    });

    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.fetchProducts();
        this.batchUpdateService.isAllProductSelected = false;
      }
    });
  }

  public toggleAllSelectProduct(): void {
    if (!this.batchUpdateService.isAllProductSelected) {
      this.batchUpdateService.isAllProductSelected = !this.batchUpdateService.isAllProductSelected;

      return;
    }
    this.batchUpdateService.isAllProductSelected = !this.batchUpdateService.isAllProductSelected;
    this.batchUpdateService.clearProductsForBatchUpdate();
  }

  public disableBatchUpdateButton(): boolean {
    return !this.batchUpdateService.isProductsForBatchUpdateEmpty() || this.batchUpdateService.isAllProductSelected;
  }

  public removeProduct(removedProduct: Product): void {
    this.products = this.products.filter(product => product.id !== removedProduct.id);
  }

  public loadMoreItems(size: number): void {
    this.paginator._changePageSize(this.paginator.pageSize + size);
  }

  public howManyProductsLeftToLoad(productsTotal: number, productsShowed: number): number {
    const left = productsTotal - productsShowed;
    if (left < 20) return left;

    return 20;  // 20 max
  }

  public getTextForProductSelectionToggle(): string {
    return this.batchUpdateService.isAllProductSelected
      ? 'Unselect all products'
      : 'Select all products';
  }

  public onStartingSearchProduct(): void {
    setTimeout(() => this.loadingService.showSpinner(), this.delayForSearchingInMilliseconds);
  }

}

