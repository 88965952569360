import { AuthService } from './auth.service';
import { ErrorModalComponent } from '../modals/error-modal/error-modal.component';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private readonly router: Router,
    private readonly matDialog: MatDialog,
    private readonly authService: AuthService,
  ) { }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 403) {
            this.matDialog.open(ErrorModalComponent, {
              data: {
                error: { message: 'Not enough permissions' }
              }
            });

            return throwError(error);
          }

          if (error.status === 401) {
            return new Observable(subscriber => {
              this.authService.login()
                .then(() => subscriber.next())
                .catch((err) => subscriber.error(err));
            })
              .pipe(
                switchMap(() => next.handle(request)),
                catchError((err) => throwError(err)),
              );
          }

          this.matDialog.open(ErrorModalComponent, { data: error });

          return throwError(error);
        }),
      );
  }

}
