import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  DashboardCharts,
  DataTopOrderedProductModel,
  DataUserModel, ProductsLikesChart, ProductsStockChart,
} from '../../entity/DashboardCharts';
import { DashboardInfo } from '../../entity/DashboardInfo';
import { DashboardService } from '../../services/dashboard.service';
import { LoadingService } from '../../components/loading/loading.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.sass']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  public info!: DashboardInfo;
  public chartsInfo!: DashboardCharts;
  public productsStock: ProductsStockChart;
  public productsLikes: ProductsLikesChart;

  public readonly usersDisplayedColumns = ['name', 'activity', 'likes', 'orders', 'silver', 'gold'];
  public users = new MatTableDataSource<DataUserModel>();
  @ViewChild('sort1')
  private readonly userSort!: MatSort;

  public readonly ordersDisplayedColumns = ['name', 'orders'];
  public topProducts = new MatTableDataSource<DataTopOrderedProductModel>();
  @ViewChild('sort2')
  private readonly orderSort!: MatSort;

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly loadingService: LoadingService,
  ) {
    this.productsStock = {
      dataset: [
        { data: [], label: 'In stock' },
        { data: [], label: 'Ordered' }
      ],
      labels: [],
      options: {
        responsive: true,
        scales: { xAxes: [{ stacked: true }], yAxes: [{ stacked: true }] }
      }
    };

    this.productsLikes = {
      data: [],
      labels: [],
      options: { responsive: true, legend: { display: false } }
    };
  }

  public ngOnInit(): void {
    this.fetchData();
  }

  public ngAfterViewInit(): void {
    this.users.sort = this.userSort;
    this.topProducts.sort = this.orderSort;
  }

  public applyFilterToUsers(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.users.filter = filterValue.trim().toLowerCase();
  }

  public applyFilterToOrders(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.topProducts.filter = filterValue.trim().toLowerCase();
  }

  private fetchData(): void {
    this.loadingService.showSpinner();

    this.dashboardService.getInfo().subscribe((dashboardInfo) => this.info = dashboardInfo);

    this.dashboardService.getCharts().subscribe({
      next: (dashboardCharts) => {
        this.chartsInfo = dashboardCharts;
        this.chartsInfo.productsByOrders.forEach(product => {
          this.productsStock.dataset[0].data?.push(product.stock);
          this.productsStock.dataset[1].data?.push(product.ordered);
          this.productsStock.labels.push(product.name);
        });

        this.chartsInfo.productsByLikes.forEach(product => {
          this.productsLikes.data?.push(product.likes);
          this.productsLikes.labels.push(product.name);
        });

        this.users.data = this.chartsInfo.users;
        this.topProducts.data = this.chartsInfo.topOrderedProducts;
      },
      complete: () => this.loadingService.hideSpinner(),
    });
  }

}
