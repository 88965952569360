<div class="loading">
  <div class="loading-text">
    <span class="loading-text-letter">L</span>
    <span class="loading-text-letter">O</span>
    <span class="loading-text-letter">A</span>
    <span class="loading-text-letter">D</span>
    <span class="loading-text-letter">I</span>
    <span class="loading-text-letter">N</span>
    <span class="loading-text-letter">G</span>
    <span class="loading-text-letter">.</span>
    <span class="loading-text-letter">.</span>
    <span class="loading-text-letter">.</span>
  </div>
</div>