import { AuthService } from './services/auth.service';
import { CartModalComponent } from './modals/cart-modal/cart-modal.component';
import { CartService } from './services/cart.service';
import { Component, OnInit } from '@angular/core';
import { FeedbackModalComponent } from './modals/feedback/feedback-modal.component';
import { Link } from './interfaces/link';
import { LoadingService } from './components/loading/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { StorageService } from './services/storage.service';
import { UserService } from './services/user.service';
import { WalletService } from './services/wallet.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.html',
  styleUrls: ['./app.sass']
})
export class AppComponent implements OnInit {

  public readonly adminLinks: Link[] = [
    { name: 'Dashboard', url: '/admin/dashboard' },
    { name: 'Orders', url: '/admin/orders' },
    { name: 'Products', url: '/admin/products' },
    { name: 'Discounts', url: '/admin/discounts' },
    { name: 'Users', url: '/admin/users' },
    { name: 'Feedbacks', url: '/admin/feedbacks' },
    { name: 'Emails', url: '/admin/emails' },
    { name: 'Transactions', url: '/admin/transactions' },
    { name: 'Reward groups', url: '/admin/reward-groups' }
  ];

  public isUserLoggedIn = false;
  public isUserAdmin = false;
  public userImageUrl$ = new Observable<string>();
  public appVersion = environment.APP_VERSION;
  public activeLink = this.adminLinks[0];

  private readonly previousLinkStorage = 'previous-link';
  private previousLink?: Link;

  constructor(
    public readonly cartService: CartService,
    public readonly walletService: WalletService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly modalService: MatDialog,
    private readonly storageService: StorageService,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService,
  ) { }

  public ngOnInit(): void {
    this.cartService.onOrderSubmit.subscribe(() => this.walletService.getInfo().subscribe());

    this.authService.isLoggedIn()
      .then(isLoggedIn => {
        if (isLoggedIn) this.onUserLoggedIn();
      });

    const previousLink = <Link>this.storageService.get(this.previousLinkStorage);
    if (previousLink) this.activeLink = previousLink;
  }

  public logOut(): void {
    this.authService.logout();
  }

  public goToAdminPage(): void {
    this.router.navigate(['/admin/dashboard']);
    this.activeLink = this.adminLinks[0];
  }

  public goToHomePage(): void {
    this.router.navigate(['']);
  }

  public goToWalletPage(): void {
    this.router.navigate(['/wallet']);
  }

  public goToOrderHistory(): void {
    this.router.navigate(['/orderHistory']);
  }

  public openCartModal(): void {
    const dialogRef = this.modalService.open(CartModalComponent, {
      width: '900px'
    });

    dialogRef.afterClosed().subscribe((orderConfirmed) => {
      if (orderConfirmed) {
        this.loadingService.showSpinner();
        this.cartService.makeOrder();
      }
    });
  }

  public isAdminPage(): boolean {
    return this.router.isActive('admin', false);
  }

  public canShowBackButton(): boolean {
    return this.router.isActive('admin/editProduct', false);
  }

  public back(): void {
    this.router.navigate([this.activeLink.url]);
  }

  public openFeedbackModal(): void {
    this.modalService.open(FeedbackModalComponent, { width: '400px' });
  }

  public changeActivePage(link: Link): void {
    this.previousLink = link;
    this.activeLink = this.previousLink;
    this.storageService.set(this.previousLinkStorage, this.previousLink);
  }

  private onUserLoggedIn(): void {
    this.isUserLoggedIn = true;
    this.isUserAdmin = this.userService.isAdmin();
    this.userImageUrl$ = this.userService.getImageUrl$();
    this.walletService.getInfo().subscribe();
  }

}
