import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { FocusableOption } from '@angular/cdk/a11y';
import { SelectableUser } from '../../../entity/SelectableUser';

@Component({
  selector: 'app-user-select-option',
  templateUrl: './user-select-option.component.html',
  styleUrls: ['./user-select-option.component.sass'],
})
export class UserSelectOptionComponent implements FocusableOption {

  @Input()
  public isSelectAllEnabled = false;
  @Input()
  public user!: SelectableUser;
  @Input()
  public isMultipleSelectionEnabled = false;
  @Input()
  public isAllSelected = false;

  @HostBinding('tabindex')
  public tabindex = '-1';

  constructor(private readonly element: ElementRef) { }

  public getLabel(): string {
    return this.user.name;
  }

  public focus(): void {
    this.element.nativeElement.focus();
  }

}
