import { Component, Input } from '@angular/core';
import { Discount } from '../../entity/Discount';
import { DiscountService } from '../../services/discount.service';

@Component({
  selector: 'app-discount-short-info',
  templateUrl: './discount-short-info.component.html',
  styleUrls: ['./discount-short-info.component.sass']
})
export class DiscountShortInfoComponent {

  @Input()
  public discount!: Discount;

  constructor(private readonly discountService: DiscountService) { }

  public getDiscountInfo(): string {
    return `${this.discount?.name} ${Math.trunc((this.discount?.value || 0) * 100)}% ${this.discountService.durationFormat(this.discount)}`;
  }

}
