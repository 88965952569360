import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Discount } from '../../entity/Discount';
import { DiscountService } from '../../services/discount.service';
import { EditableProductSummary } from '../../entity/ProductSummary';
import { LoadingService } from '../loading/loading.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Product } from '../../entity/Product';
import { ProductCreateRequestBody } from '../../entity/ProductCreateRequestBody';
import { ProductDiscount } from '../../entity/ProductDiscount';
import { ProductService } from '../../services/product.service';
import { ProductUpdateRequestBody } from '../../entity/ProductUpdateRequestBody';
import { maxProductPrice } from '../../constants/constants';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.html',
  styleUrls: ['./edit-product.sass']
})
export class EditProductComponent implements OnInit {

  public readonly maxProductPrice = maxProductPrice;

  @Input()
  public product?: Product;
  @Input()
  public discounts: Discount[] = [];
  @Output()
  public newProductEvent = new EventEmitter<Product>();
  @Output()
  public updateProductEvent = new EventEmitter<Product>();
  @Output()
  public changeProductEvent = new EventEmitter<Product>();

  @ViewChild('formElement')
  private readonly formElement?: HTMLFormElement;

  public productForm: UntypedFormGroup;
  public minimalDiscountStartDate = new Date();

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly productService: ProductService,
    private readonly snackBarService: MatSnackBar,
    private readonly discountService: DiscountService,
    private readonly loadingService: LoadingService,
  ) {
    this.productForm = this.formBuilder.group({
      name: [
        '',
        [
          Validators.maxLength(100),
          Validators.required
        ]
      ],
      description: [
        '',
        [
          Validators.maxLength(255),
          Validators.required
        ]
      ],
      price: [
        null,
        [
          Validators.min(0),
          Validators.max(maxProductPrice),
          Validators.required
        ]
      ],
      quantity: [
        null,
        [
          Validators.min(1),
          Validators.max(1000),
          Validators.required
        ]
      ],
      discount: [
        undefined, []
      ],
      isDelayedPublication: [
        false, []
      ],
      discountStartDate: [
        undefined,
        [
          Validators.required,
          (control: AbstractControl) => {
            return new Date() > new Date(control.value)
              ? { invalidDate: control.value }
              : null;
          },
        ]
      ]
    });
  }

  public ngOnInit(): void {
    this.productForm.patchValue({
      name: this.product?.name,
      description: this.product?.description,
      price: this.product?.price,
      quantity: this.product?.quantity,
      discount: this.product?.productDiscount?.discount,
      discountStartDate: this.product?.productDiscount?.discountStartDate
    });

    this.enableDatepicker(this.isDelayedPublicationVisible() && !!this.product?.productDiscount?.discountStartDate);

    this.productForm.valueChanges.subscribe((editedProductValues: EditableProductSummary) => {
      const editedProduct = { ...this.product } as Product;
      const productDiscount: ProductDiscount = {
        discount: editedProductValues.discount,
        discountStartDate: editedProductValues.discountStartDate || new Date().toString(),
      };

      editedProduct.name = editedProductValues.name;
      editedProduct.price = editedProductValues.price;
      editedProduct.quantity = editedProductValues.quantity;
      editedProduct.description = editedProductValues.description;
      editedProduct.productDiscount = editedProductValues.discount ? productDiscount : {} as ProductDiscount;
      this.changeProductEvent.emit(editedProduct);
    });

    this.loadingService.hideSpinner();
  }

  public isInValid(fieldName: string): boolean {
    const field = this.productForm.get(fieldName);

    return field ? field.invalid && field.dirty : false;
  }

  public onFormSubmit(editableProduct: EditableProductSummary): void {
    const requestBody: ProductUpdateRequestBody = {
      id: this.product?.id || '',
      name: editableProduct.name,
      description: editableProduct.description,
      price: editableProduct.price,
      quantity: editableProduct.quantity,
      discountId: editableProduct.discount?.id,
      discountStartDate: editableProduct.discountStartDate
    };

    if (this.product && requestBody.id.length) {
      this.updateProduct(requestBody);
    } else {
      this.createProduct(requestBody);
    }
  }

  public isDiscountsEquals(discount1?: Discount, discount2?: Discount): boolean {
    return !!(discount1 && discount2 && discount1.id === discount2.id);
  }

  public enableDatepicker(isEnabled: boolean): void {
    this.productForm.get('isDelayedPublication')?.setValue(isEnabled);

    if (isEnabled) {
      this.productForm.get('discountStartDate')?.enable();
    } else {
      this.productForm.get('discountStartDate')?.disable();
    }
  }

  public isDelayedPublicationVisible(): boolean {
    if (!this.productForm.get('discount')?.value) {
      return false;
    }

    if (!this.isDiscountChanged()) {
      return !this.isDiscountPublished();
    }

    return true;
  }

  private updateProduct(requestBody: ProductUpdateRequestBody): void {
    if (!this.product) {
      return;
    }

    this.loadingService.showSpinner();

    requestBody.id = this.product.id;

    if (this.isDelayedPublicationVisible()) {
      if (this.productForm.get('isDelayedPublication')?.value) {
        requestBody.discountStartDate = this.productForm.get('discountStartDate')?.value;
      }
    } else {
      requestBody.discountStartDate = this.product.productDiscount.discountStartDate;
    }

    this.productService.updateProduct(requestBody).subscribe({
      next: (updatedProduct) => {
        this.product = { ...updatedProduct };
        this.productForm.patchValue({
          isDelayedPublication: this.isDelayedPublicationVisible() && !!updatedProduct.productDiscount?.discountStartDate,
          discountStartDate: updatedProduct.productDiscount?.discountStartDate,
        });
        this.updateProductEvent.emit(updatedProduct);
        this.snackBarService.open('Successfully update product', undefined, { duration: 5000 });
      },
      complete: () => this.loadingService.hideSpinner(),
    });
  }

  private createProduct(requestBody: ProductCreateRequestBody): void {
    this.loadingService.showSpinner();
    this.productService.createProduct(requestBody).subscribe({
      next: (newProduct) => {
        // Remove after backend start returning full product with likes and images
        newProduct.likes = newProduct.likes || [];
        newProduct.images = newProduct.images || [];
        this.newProductEvent.emit(newProduct);
        this.snackBarService.open('Successfully added product', undefined, { duration: 5000 });
        this.productForm.reset();
        this.formElement?.nativeElement?.reset();
      },
      complete: () => this.loadingService.hideSpinner(),
    });
  }

  private isDiscountPublished(): boolean {
    return !!(this.product && this.discountService.getIsDiscountPublished(this.product.productDiscount));
  }

  private isDiscountChanged(): boolean {
    const newDiscount = this.productForm.get('discount')?.value;

    return !this.isDiscountsEquals(newDiscount, this.product?.productDiscount?.discount);
  }

}

