import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { ErrorModalComponent } from '../modals/error-modal/error-modal.component';
import { Injectable } from '@angular/core';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {

  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private readonly authService: AuthService,
    private readonly matDialog: MatDialog,
  ) {
    super(router, keycloak);
  }

  public isAccessAllowed(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise(resolve => {
      if (!this.authenticated) {
        this.authService.login();

        return resolve(false);
      }

      const requiredRoles: string[] = route.data.roles || [];
      const isGranted = requiredRoles.length
        ? requiredRoles.some(role => this.keycloak.isUserInRole(role))
        : true;

      if (!isGranted) {
        this.router.navigate(['']).finally(() =>
          this.matDialog.open(ErrorModalComponent, {
            data: {
              error: { message: 'You don\'t have access to see that page' }
            }
          }),
        );
      }

      resolve(isGranted);
    });
  }

}
