<h1 mat-dialog-title>Why order status changed?</h1>

<mat-dialog-content>
  <mat-form-field color="accent" style="width: 100%">
    <mat-label>Comment</mat-label>
    <textarea matInput [(ngModel)]="comment"></textarea>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-button mat-raised-button color="accent" mat-dialog-close (click)="closeModal(comment)">Cancel</button>
  <button mat-button mat-raised-button color="accent"
          (click)="submitModal(comment)"
          [disabled]="isCommentRequired && !comment.length">
    Submit
  </button>
</mat-dialog-actions>
