import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Resolve } from '@angular/router';
import { User } from '../entity/User';
import { UserManagementService } from '../services/user-management.service';
import { UserService } from '../services/user.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserInfoResolver implements Resolve<User> {

  constructor(
    private readonly userApi: UserManagementService,
    private readonly userService: UserService,
  ) { }

  public resolve(): Observable<User> | User {
    const cachedUser = this.userService.user;

    return cachedUser
      ? cachedUser
      : this.userApi.getCurrentUser()
        .pipe(
          tap(user => this.userService.user = user),
        );
  }

}
