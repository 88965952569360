import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DiscountService } from '../../services/discount.service';
import { ProductDiscountSummary } from '../../entity/ProductDiscountSummary';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-discount-tag',
  templateUrl: './discount-tag.component.html',
  styleUrls: ['./discount-tag.component.sass']
})
export class DiscountTagComponent implements OnInit, OnDestroy {

  @Input()
  public productDiscount!: ProductDiscountSummary;
  @Input()
  public discountPercentField = '0%';
  @Input()
  public isUserAdmin?: boolean = false;

  public discountTimeField = '--:--:--';
  public isDiscountInactive = false;
  public isEnded = false;

  private subscriptionOnTimer?: Subscription;

  constructor(private readonly discountService: DiscountService) { }

  public ngOnInit(): void {
    this.subscriptionOnTimer = this.discountService.onDiscountTimeUpdate(() => {
      this.isEnded = this.discountService.getIsDiscountExpired(this.productDiscount);
      this.discountPercentField = this.discountService.getPercentValue(this.productDiscount.discount);
      this.discountTimeField = this.discountService.getFormattedTimeLeft(this.productDiscount);
      this.productDiscount.discount.isActive = this.discountService.getIsDiscountEnabled(this.productDiscount) || Boolean(this.isUserAdmin);

      if (this.isUserAdmin && !this.discountService.getIsDiscountPublished(this.productDiscount)) {
        this.isDiscountInactive = true;

        const publishDate = new Date(this.productDiscount.discountStartDate);
        this.discountTimeField = this.discountService.getFormattedPublishDate(publishDate);
      } else {
        this.isDiscountInactive = false;
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptionOnTimer?.unsubscribe();
  }

}
