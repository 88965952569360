import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { DiscountService } from '../../services/discount.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.html',
  styleUrls: ['./cart-modal.sass']
})
export class CartModalComponent implements OnInit, AfterViewInit, OnDestroy {

  public error = this.modalErrorData;
  public isDiscountTimeOver = false;
  public subscriptionsOnTimer: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly modalErrorData: Error,
    private readonly dialogRef: MatDialogRef<CartModalComponent>,
    public readonly cartService: CartService,
    public readonly discountService: DiscountService,
  ) { }

  public ngAfterViewInit(): void {
    this.cartService.reloadCartProducts();
  }

  public ngOnInit(): void {
    this.cartService.cart.items.forEach((item) => {
      const productDiscount = item.product.productDiscount;
      if (productDiscount.discount?.duration) {
        const subscription = this.discountService.onDiscountTimeUpdate(() => {
          if (!this.discountService.getIsDiscountEnabled(productDiscount)) {
            this.isDiscountTimeOver = true;
          }
        });

        this.subscriptionsOnTimer.push(subscription);
      }
    }
    );
  }

  public closeModal(orderConfirmed: boolean): void {
    this.dialogRef.close(orderConfirmed);
  }

  public ngOnDestroy(): void {
    this.subscriptionsOnTimer.forEach((subscription) => subscription.unsubscribe());
  }

}




