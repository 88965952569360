import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductImage } from '../entity/ProductImage';
import { environment } from '../../environments/environment';

@Injectable()
export class ProductImageService {

  constructor(
    private readonly httpClient: HttpClient,
  ) { }

  public getAllByProduct(productId: string): Observable<ProductImage> {
    const url = environment.API_URL.concat(`/product-images/product${productId}`);

    return this.httpClient.get<ProductImage>(url);
  }

  public getOne(id: string): Observable<ProductImage> {
    const url = environment.API_URL.concat(`/product-images/${id}`);

    return this.httpClient.get<ProductImage>(url);
  }

  public create(productId: string, file: FormData): Observable<ProductImage> {
    const url = environment.API_URL.concat(`/product-images/product/${productId}`);

    return this.httpClient.post<ProductImage>(url, file);
  }

  public remove(id: string): Observable<void> {
    const url = environment.API_URL.concat(`/product-images/${id}`);

    return this.httpClient.delete<void>(url);
  }

}
