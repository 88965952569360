<section class="order-management">
    <div>
        <h2 fxFlex="1 1 auto"
            fxLayoutAlign="center center"
            class="title">
            My Orders
        </h2>
        <mat-paginator (page)="pageEvent = $event; fetchOrders()"
                       [length]="length"
                       [pageSizeOptions]="pageSizeOptions"
                       [pageSize]="pageSize">
        </mat-paginator>
    </div>

    <br>

    <mat-accordion class="orders-accordion" multi>
        <mat-expansion-panel *ngFor="let order of orders.data">
            <mat-expansion-panel-header class="panel-header">
                <mat-panel-title class="panel-title">
                    <span class="order-number">Order №{{ order.id }}</span>
                    <span> — {{ order.createdAt | date }}</span>
                </mat-panel-title>
                <mat-panel-description class="panel-description">
                    <span class="order-info">
                        {{ order.totalQuantity }}
                        {{ order.totalQuantity > 1 ? 'items' : 'item' }}
                        for the amount of <span class="price"> {{ order.totalValue }}
                        <app-coin type="silver"></app-coin></span>
                    </span>
                    <span class="order-status" matTooltip="{{ getOrderStatusDetails(order.status).tooltip }}">
                        Status:
                        <mat-icon class="order-status-icon">{{ getOrderStatusDetails(order.status).icon }}</mat-icon>
                    </span>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="order-details">
                <mat-divider></mat-divider>
                <ng-container *ngIf="order.items?.length; else noItems">
                    <span>Items:</span>
                    <div class="order-items">
                        <div *ngFor="let item of order.items" class="order-item">
                            <div class="item-image-container">
                                <img [src]="item.productImageUrl || '../../assets/default-product.png'"
                                     [alt]="item.productName"
                                     class="item-image">
                            </div>
                            <div class="item-info">
                                <span>{{ item.productName }}</span>
                                <span>Quantity - {{ item.quantity }}</span>
                                <div class="price">
                                    <span>{{ item.value }}</span>
                                    <app-coin type="silver"></app-coin>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #noItems>
                    <div class="no-items-message">
                        <p class="message">No items found in this order</p>
                        <mat-icon class="sad-icon" color="warn">sentiment_dissatisfied</mat-icon>
                    </div>
                </ng-template>
                <div class="order-actions">
                    <button #cancelButton [disabled]="order.status !== 'NEW'"
                            mat-raised-button
                            color="warn"
                            class="cancel-button"
                            (click)="cancelOrder(order)">
                        <span>Cancel Order</span>
                        <mat-icon class="cancel-button-icon"
                          [ngClass]="{'cancel-button-icon-disabled': cancelButton.disabled}">cancel</mat-icon>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>
