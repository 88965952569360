import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Discount } from '../../entity/Discount';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { LoadingService } from '../../components/loading/loading.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Product } from '../../entity/Product';
import { ProductImageService } from '../../services/product-image.service';
import { ProductService } from '../../services/product.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-manage-product-images',
  templateUrl: './manage-product.html',
  styleUrls: ['./manage-product.sass']
})
export class ManageProductComponent implements OnInit {

  public imageChangedEvent: Event | undefined;
  public croppedImage = '';
  public product!: Product;
  public previewProduct!: Product;
  public discounts: Discount[] = [];

  private productId!: string;
  public isImageLoaded = false;

  constructor(
    private readonly productService: ProductService,
    private readonly productImageService: ProductImageService,
    private readonly snackBarService: MatSnackBar,
    private readonly route: ActivatedRoute,
    private readonly loadingService: LoadingService,
  ) {
    this.route.params.pipe(map(p => p.id)).subscribe(result => {
      this.productId = result;
    });
  }

  public ngOnInit(): void {
    this.productService.getProduct(this.productId).subscribe({
      next: (product) => {
        this.product = product;
        this.previewProduct = product;
      },
      error: (error) => console.error(error),
    });
    this.productService.getDiscounts().subscribe({
      next: (discounts) => this.discounts = discounts.data,
      error: (error) => console.error(error),
    });
  }

  public fileChangeEvent(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const isFileSelected = !!inputElement && inputElement.files!.length > 0;

    if (isFileSelected) {
      this.isImageLoaded = false;
      this.imageChangedEvent = event;
    }
  }

  public imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64 as string;
  }

  public uploadImage(): void {
    this.loadingService.showSpinner();

    const base64 = this.croppedImage;

    fetch(base64)
      .then(res => res.blob())
      .then(blob => {
        const formData = new FormData();
        const fileName = `${this.product.name} ${this.product.images.length + 1}.png`;
        const file = new File([blob], fileName, { type: 'image/png' });
        formData.append('file', file);

        this.productImageService.create(this.product.id, formData).subscribe({
          next: (image) => {
            this.previewProduct.images.push(image);
            this.snackBarService.open('Successful upload image', undefined, { duration: 5000 });
            this.croppedImage = '';
            this.imageChangedEvent = undefined;
          },
          error: (error) => console.error(error),
          complete: () => this.loadingService.hideSpinner(),
        });
      });
  }

  public removeImageById(id: string): void {
    this.previewProduct.images = this.previewProduct.images.filter(image => image.id !== id);
    this.productImageService.remove(id).subscribe();
  }

  public onProductUpdate(product: Product): void {
    this.product = Object.assign(this.product, product);
  }

  public onPreviewProductChanged(product: Product): void {
    this.previewProduct = Object.assign(this.previewProduct, product);
  }

  public onImageLoaded(): void {
    this.isImageLoaded = true;
  }

}

