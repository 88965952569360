import { AddCoinsModalComponent } from '../../modals/add-coins-modal/add-coins-modal.component';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { LoadingService } from '../loading/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { RoleEnum } from '../../enums/RoleEnum';
import { User } from '../../entity/User';
import { UserManagementService } from '../../services/user-management.service';
import { WalletService } from '../../services/wallet.service';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-users-management',
  templateUrl: './users-management.html',
  styleUrls: ['./users-management.sass']
})
export class UsersManagementComponent implements OnInit, AfterViewInit {

  @ViewChild('searchFieldElement')
  private readonly searchFieldElement!: ElementRef;
  @ViewChild(MatSort)
  private readonly sort!: MatSort;

  public searchField = '';
  public length = 100;
  public pageEvent!: PageEvent;
  public users = new MatTableDataSource<User>();
  public isLoaded = false;

  public readonly pageSize = 10;
  public readonly pageSizeOptions = [5, 10, 25, 100];
  public readonly displayedColumns = ['name', 'email', 'coin', 'role', 'status', 'actions'];

  constructor(
    private readonly userManagementService: UserManagementService,
    private readonly modalService: MatDialog,
    private readonly walletService: WalletService,
    private readonly snackBarService: MatSnackBar,
    private readonly loadingService: LoadingService,
  ) { }

  public ngOnInit(): void {
    this.fetchUsers();
  }

  public ngAfterViewInit(): void {
    this.users.sort = this.sort;
    this.sort.sortChange.subscribe(() => this.fetchUsers());
    fromEvent(this.searchFieldElement.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.fetchUsers();
        })
      )
      .subscribe();
  }

  public fetchUsers(query = this.searchField || '',
    pageSize = this.pageEvent?.pageSize || 10,
    page = this.pageEvent?.pageIndex || 0,
    sort = this.sort ? `${this.sort.active},${this.sort.direction}` : 'name,asc'
  ): void {
    this.loadingService.showSpinner();
    this.userManagementService.getUsers(query, pageSize, page, sort)
      .subscribe({
        next: (users) => {
          this.length = users.totalCount;
          this.users.data = users.data;
        },
        complete: () => {
          this.isLoaded = true;
          this.loadingService.hideSpinner();
        },
      });
  }

  public enableUser(user: User): void {
    this.loadingService.showSpinner();
    this.userManagementService.enableUser(user.id).subscribe({
      next: () => user.isActive = true,
      complete: () => this.loadingService.hideSpinner(),
    });
  }

  public disableUser(user: User): void {
    const dialogRef = this.modalService.open(ConfirmModalComponent);

    dialogRef.afterClosed().subscribe(userConfirmAction => {
      if (userConfirmAction) {
        this.loadingService.showSpinner();
        this.userManagementService.disableUser(user.id)
          .subscribe({
            next: () => user.isActive = false,
            complete: () => this.loadingService.hideSpinner(),
          });
      }
    });
  }

  public makeUserAdmin(user: User): void {
    const dialogRef = this.modalService.open(ConfirmModalComponent);

    dialogRef.afterClosed().subscribe((userConfirmAction) => {
      if (userConfirmAction) {
        this.loadingService.showSpinner();
        this.userManagementService.makeUserAdmin(user.id).subscribe({
          next: () => user.role = RoleEnum.ADMIN,
          complete: () => this.loadingService.hideSpinner(),
        });
      }
    });
  }

  public openAddCoinsModal(): void {

    this.userManagementService.getUsersSummary('', 1000, 0, 'name,asc')
      .subscribe((res) => {

        const dialogRef = this.modalService.open(AddCoinsModalComponent, {
          data: { users: res.data },
          minWidth: 500
        });

        dialogRef.afterClosed().subscribe((data) => {
          if (data) {
            this.loadingService.showSpinner();
            this.walletService.addCoins(data)
              .subscribe(() => {
                this.snackBarService.open('Coins added successfully', undefined, { duration: 5000 });
                this.fetchUsers();
              });
          }
        });
      });
  }

}
