<div *ngIf="cartService.isCartEmpty()" fxLayout="column" fxLayoutAlign="center center">

  <h1>It's empty</h1>
  <img src="../../assets/sentiment_neutral.svg" alt="Neutral face" style="width: 100px; margin-bottom: 32px">
</div>

<div *ngIf="!cartService.isCartEmpty()">
  <section gdColumns="repeat( auto-fill, minmax(200px, 1fr))" gdGap="16px">
    <ng-container *ngFor="let item of cartService.cart.items">

      <mat-card style="padding: 0; margin: 0;" fxLayout="column">
        <div class="card-header">

          <div class="card-title">{{item.product.name}}</div>

          <app-discount-tag *ngIf="discountService.getIsDiscountEnabled(item.product.productDiscount)"
                            [isUserAdmin]="isViewForAdmin"
                            [productDiscount]="item.product.productDiscount" class="discount">
            Discount
          </app-discount-tag>

          <div>
            <mat-icon color="accent"
                      (click)="cartService.removeFromCart(item.product)"
                      matTooltip="Remove from cart"
                      class="card-remove-item">
              remove_shopping_cart
            </mat-icon>
          </div>
        </div>

        <div style="display: flex; height: 100%;">
            <img mat-card-image
                 style="margin: 0; padding: 0; width: 100%; object-fit: contain;"
                 [src]="item.product.images[0]?.url || '../../assets/default-product.png'"
                 [alt]="item.product.name">
        </div>

        <mat-card-actions style="padding:0; margin: 0 8px 0 4px">
          <div fxLayout="row">
            <div fxFlex fxLayoutAlign="start center">
              <button mat-icon-button
                      (click)="item.quantity = item.quantity === 1 ? item.quantity : item.quantity - 1"
                      [matTooltip]="item.quantity === 1 ? 'Reached minimum amount' : 'Reduce quantity'"
                      [disabled]="item.quantity === 1">
                <mat-icon [ngClass]="{'disabled-color': item.quantity === 1}">remove_circle</mat-icon>
              </button>
              <span style="margin: 0 4px">{{item.quantity}}</span>
              <button mat-icon-button
                      (click)="item.quantity = item.product.quantity > item.quantity ? item.quantity  + 1 : item.quantity"
                      [matTooltip]="item.product.quantity > item.quantity || item.quantity > 100 ? 'Increase quantity' : 'Reached maximum amount'"
                      [disabled]="item.product.quantity === item.quantity || item.quantity >= 100">
                <mat-icon
                          [ngClass]="{'disabled-color': item.product.quantity === item.quantity || item.quantity >= 100}">
                  add_circle
                </mat-icon>
              </button>
            </div>

            <div fxLayoutAlign="end center" class="no_break">
              {{discountService.getPriceWithDiscount(item.product.price, item.product.productDiscount) * item.quantity}}
              <app-coin type="silver"></app-coin>
            </div>
          </div>
        </mat-card-actions>
      </mat-card>

    </ng-container>
  </section>

  <mat-form-field color="accent" style="margin-top: 16px; width: 100%;">
    <mat-label>Comment:</mat-label>
    <textarea matInput [(ngModel)]="cartService.cart.comment" maxlength="255"></textarea>
  </mat-form-field>

  <h2 style="margin: 0;" fxLayout="row">
    Total cost: {{cartService.totalCartPrice}}
    <app-coin type="silver"></app-coin>
  </h2>

</div>
