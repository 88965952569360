import { Discount } from '../entity/Discount';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../entity/Product';
import { ProductCreateRequestBody } from '../entity/ProductCreateRequestBody';
import { ProductSummary } from '../entity/ProductSummary';
import { ProductUpdateRequestBody } from '../entity/ProductUpdateRequestBody';
import { User } from '../entity/User';
import { environment } from '../../environments/environment';

@Injectable()
export class ProductService {

  constructor(
    private readonly httpClient: HttpClient,
  ) { }

  // Really it returns ProductSummary[]
  public getProductsSummary(query: string, size: number, page: number, sort: string): Observable<{ data: Product[], totalCount: number }> {
    const url = environment.API_URL.concat('/product/summary');
    const params = new HttpParams()
      .set('query', query)
      .set('size', size.toString(10))
      .set('page', page.toString(10))
      .set('sort', sort);

    return this.httpClient.get<{ data: Product[], totalCount: number }>(url, { headers: new HttpHeaders(), params });
  }

  public getProducts(query: string, size: number, page: number, sort: string, isOnlyAvailable: boolean): Observable<{ data: Product[], totalCount: number }> {
    const url = environment.API_URL.concat('/product');
    const params = new HttpParams()
      .set('query', query)
      .set('size', size.toString(10))
      .set('page', page.toString(10))
      .set('sort', sort)
      .set('isOnlyAvailable', isOnlyAvailable);

    return this.httpClient.get<{ data: Product[], totalCount: number }>(url, { headers: new HttpHeaders(), params });
  }

  public getDiscounts(): Observable<{ data: Discount[] }> {
    const url = environment.API_URL.concat('/discount');

    return this.httpClient.get<{ data: Discount[] }>(url, { headers: new HttpHeaders(), params: new HttpParams() });
  }

  public createProduct(product: ProductCreateRequestBody): Observable<Product> {
    const url = environment.API_URL.concat('/product');

    return this.httpClient.post<Product>(url, product, { headers: new HttpHeaders() });
  }

  public getProduct(id: string): Observable<Product> {
    const url = environment.API_URL.concat(`/product/${id}`);

    return this.httpClient.get<Product>(url, { headers: new HttpHeaders() });
  }

  public getProductSummary(id: string): Observable<ProductSummary> {
    const url = environment.API_URL.concat(`/product/${id}/summary`);

    return this.httpClient.get<ProductSummary> (url, { headers: new HttpHeaders() });
  }

  public updateProduct(product: ProductUpdateRequestBody): Observable<Product> {
    const url = environment.API_URL.concat(`/product/${product.id}`);

    return this.httpClient.put<Product>(url, product, { headers: new HttpHeaders() });
  }

  public removeProduct(id: string): Observable<void> {
    const url = environment.API_URL.concat(`/product/${id}`);

    return this.httpClient.delete<void>(url, { headers: new HttpHeaders() });
  }

  public enableProduct(id: string): Observable<void> {
    const url = environment.API_URL.concat(`/product/${id}/enable`);

    return this.httpClient.put<void>(url, {}, { headers: new HttpHeaders() });
  }

  public disableProduct(id: string): Observable<void> {
    const url = environment.API_URL.concat(`/product/${id}/disable`);

    return this.httpClient.put<void>(url, {}, { headers: new HttpHeaders() });
  }

  public isUserLikedProduct(product: Product, user: User): boolean {
    return !!product.likes.find((productLike) => productLike.id === user.id);
  }

  public likeProduct(id: string): Observable<ProductSummary> {
    const url = environment.API_URL.concat(`/product/${id}/like`);

    return this.httpClient.put<ProductSummary>(url, {}, { headers: new HttpHeaders() });
  }

}
