import { Component } from '@angular/core';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { FeedbackService } from '../../services/feedback.service';
import { LoadingService } from '../../components/loading/loading.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.html',
  styleUrls: ['./feedback-modal.sass']
})
export class FeedbackModalComponent {

  public message = '';

  constructor(
    private readonly dialogRef: MatDialogRef<FeedbackModalComponent>,
    private readonly matDialog: MatDialog,
    private readonly feedbackService: FeedbackService,
    private readonly snackBarService: MatSnackBar,
    private readonly loadingService: LoadingService,
  ) { }

  public sendFeedback(): void {
    if (!this.message) {
      return;
    }

    this.loadingService.showSpinner();
    this.message = this.message.trim();

    this.feedbackService.postFeedback(this.message).subscribe({
      next: () => this.snackBarService.open('Thanks for your feedback! Your opinion is important for us!', undefined, { duration: 4000 }),
      error: (error) => this.matDialog.open(ErrorModalComponent, { data: error }),
      complete: () => this.loadingService.hideSpinner(),
    });
  }

}
