<span
  [ngClass]="{'user_short_info': true, 'user_short_info--clickable': isClickable}"
  gdColumns="max-content 1fr"
  fxLayoutAlign="start center"
>
  <img
    class="user_short_info__img"
    [src]="user?.imageUrl"
    alt="{{user?.name}}'s photo"
    height="32px"
    style="border-radius: 50%; margin: 0 8px;"
    onerror="this.onerror=null;this.src='../../assets/person-white-36dp.svg';"
  >
  <span class="user_short_info__name">{{user?.name || 'Undefined user'}}</span>
</span>
