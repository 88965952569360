import { CacheStorageService } from './cache.storage.service';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { StorageService } from './storage.service';
import { environment } from '../../environments/environment';

export function initKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
  const keycloakInit = keycloak.init({
    config: {
      url: environment.AUTH_URL,
      realm: environment.REALM_ID,
      clientId: environment.CLIENT_ID,
    },
    loadUserProfileAtStartUp: true,
    initOptions: {
      redirectUri: environment.LOGOUT_REDIRECT_URL,
      flow: 'implicit',
      checkLoginIframe: true,
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/assets/sso/silent-check-sso.html',
    },
  });

  return () => keycloakInit;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private readonly storageService: StorageService,
    private readonly cache: CacheStorageService,
    private readonly keycloak: KeycloakService,
  ) { }

  public async login(): Promise<void> {
    const currentUrl = window.location.origin + window.location.pathname;
    await this.keycloak.login({ redirectUri: currentUrl });
  }

  public isLoggedIn(): Promise<boolean> {
    return this.keycloak.isLoggedIn();
  }

  public async logout(): Promise<void> {
    this.storageService.clear();
    this.cache.clear();

    await this.keycloak.logout(environment.LOGOUT_REDIRECT_URL);
  }

}
