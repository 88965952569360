<div matDialogTitle>
  Products in cart
  <div fxFlex="1 1 auto"></div>

  <button mat-button
          mat-icon-button
          (click)="cartService.clearCart()"
          color="accent"
          matTooltip="Remove all products from cart"
          *ngIf="!cartService.isCartEmpty()">
    <mat-icon class="quantity-icon" color="accent">delete</mat-icon>
  </button>

  <button mat-button mat-icon-button (click)="closeModal(false)" color="accent" matTooltip="Close cart modal window">
    <mat-icon autofocus class="quantity-icon" color="accent">clear</mat-icon>
  </button>

</div>

<div mat-dialog-content style="overflow-x: hidden;">
  <app-cart></app-cart>
</div>

<div *ngIf="!cartService.isMoneyEnough()" style="text-align: center; margin-top: 8px">
  <mat-error>Sorry bro, but you don't have enough coins.</mat-error>
</div>

<div *ngIf="isDiscountTimeOver" style="text-align: center; margin-top: 8px">
  <mat-error>The time of one or more discounts is over. Continue?</mat-error>
</div>

<div mat-dialog-actions style="padding-bottom: 24px">
  <div fxFlex="1 1 auto"></div>
  <button (click)="closeModal(false)"
          mat-button
          mat-raised-button
          color="accent">
    Close
  </button>
  <button *ngIf="!cartService.isCartEmpty()"
          (click)="closeModal(true)"
          mat-button
          mat-raised-button
          color="accent"
          [disabled]="!cartService.isMoneyEnough()">
    Make order
  </button>
  <div fxFlex="1 1 auto"></div>
</div>
