import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-coin',
  templateUrl: './coin.html',
  styleUrls: ['./coin.sass']
})
export class CoinComponent {

  @Input() public type = 'gold';
  @Input() public size = 'normal';

}

