import { Injectable } from '@angular/core';

@Injectable()
export class CacheStorageService {

  private readonly storage = sessionStorage;

  public set(key: string, value: unknown): void {
    this.storage.setItem(key, JSON.stringify(value));
  }

  public get(key: string): unknown  {
    const data = this.storage.getItem(key);

    return data ? JSON.parse(data) : undefined;
  }

  public clear(): void {
    this.storage.clear();
  }

  public remove(key: string): void {
    this.storage.removeItem(key);
  }

}
