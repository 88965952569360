import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductService } from '../../services/product.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserSummary } from '../../entity/UserSummary';

@Component({
  selector: 'app-transfer-gold-modal',
  templateUrl: './transfer-gold-modal.html',
  styleUrls: ['./transfer-gold-modal.sass']
})
export class TransferGoldModalComponent {

  public maxCoinsAmount = this.data.coins;
  public users = this.data.users;
  public selectedUserId?: string;
  public transferForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { users: UserSummary[], coins: number },
    private readonly formBuilder: UntypedFormBuilder,
    private readonly productService: ProductService,
    private readonly snackBarService: MatSnackBar,
    private readonly dialogRef: MatDialogRef<TransferGoldModalComponent>,
  ) {
    this.transferForm = this.formBuilder.group({
      coins: [0, [Validators.min(1),
        Validators.max(this.maxCoinsAmount),
        Validators.required]],
      comment: ['', [Validators.maxLength(255)]],
      anonymously: false,
    });
  }

  public isInValid(fieldName: string): boolean {
    const field = this.transferForm.get(fieldName);

    return field ? field.invalid && field.dirty : false;
  }

  public onFormSubmit(): void {
    if (this.selectedUserId && this.transferForm.value.coins) {
      this.closeModal({
        userId: this.selectedUserId,
        coins: this.transferForm.value.coins,
        comment: this.transferForm.value.comment.trim(),
        anonymously: this.transferForm.value.anonymously,
      });
    }
  }

  public closeModal(transferData: { userId: string, coins: number, comment: string, anonymously: boolean } | null): void {
    this.dialogRef.close(transferData);
  }

  public onSelectUserIds(userIds: string[]): void {
    this.selectedUserId = userIds[0];
  }

}
