import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-order-status-modal',
  templateUrl: './change-order-status-modal.html',
  styleUrls: ['./change-order-status-modal.sass']
})
export class ChangeOrderStatusModalComponent {

  public comment = '';
  public isCommentRequired = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly isCommentRequiredModalData: boolean,
    private readonly dialogRef: MatDialogRef<ChangeOrderStatusModalComponent>,
  ) {
    if (isCommentRequiredModalData !== null) {
      this.isCommentRequired = isCommentRequiredModalData;
    }
  }

  public submitModal(comment: string): void {
    this.dialogRef.close({ comment, confirmed: true });
  }

  public closeModal(comment: string): void {
    this.dialogRef.close({ comment, confirmed: false });
  }

}

