<div style="margin-top: 16px">
  <div fxFlex="1 1 auto"></div>
<!--  <mat-form-field>-->
<!--    <mat-label>Search</mat-label>-->
<!--    <input [(ngModel)]="searchField" matInput value="" #searchFieldElement>-->
<!--  </mat-form-field>-->

  <mat-paginator (page)="pageEvent = $event; fetchOrders()"
                 [length]="length"
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize">
  </mat-paginator>
</div>

<table [dataSource]="orders" class="mat-elevation-z8"
       mat-table
       matSort matSortActive="createdAt" matSortDirection="desc" matSortDisableClear
>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let order">
      <mat-icon *ngIf="order.status === 'NEW'" matTooltip="New order">child_friendly</mat-icon>
      <mat-icon *ngIf="order.status === 'IN_PROCESS'" matTooltip="Order is processing">engineering</mat-icon>
      <mat-icon *ngIf="order.status === 'PROCESSED'" matTooltip="Order is delivered">done_outline</mat-icon>
      <mat-icon *ngIf="order.status === 'CANCELED'" matTooltip="Order is canceled">cancel</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="totalValue">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Price</th>
    <td mat-cell *matCellDef="let order" style="padding-left: 4px"> {{order.totalValue}} </td>
  </ng-container>

  <ng-container matColumnDef="totalQuantity">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Quantity</th>
    <td mat-cell *matCellDef="let order" style="padding-left: 4px"> {{order.totalQuantity}} </td>
  </ng-container>

  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef>User</th>
    <td mat-cell *matCellDef="let order"> {{order.user.name}}</td>
  </ng-container>

  <ng-container matColumnDef="items">
    <th mat-header-cell *matHeaderCellDef>Items</th>
    <td mat-cell *matCellDef="let order">
      <p class="order-item" *ngFor="let item of order.items;">
        {{item.product.name}} - {{item.quantity}}
      </p>
    </td>
  </ng-container>

  <ng-container matColumnDef="comment">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Comment</th>
    <td mat-cell *matCellDef="let order">{{order.comment}}</td>
  </ng-container>

  <ng-container matColumnDef="adminComment">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Admin comment</th>
    <td mat-cell *matCellDef="let order">{{order.adminComment}}</td>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Created at</th>
    <td mat-cell *matCellDef="let order">{{order.createdAt | date}}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let order">
      <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Product actions">
        <mat-icon color="accent">more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button [disabled]="order.status === 'NEW' || order.status === 'CANCELED' || order.status === 'PROCESSED'"
                (click)="setStatusForOrder(order, 'NEW')"
                mat-menu-item
        >
          <mat-icon color="accent">child_friendly</mat-icon>
          <span>Mark as new</span>
        </button>

        <button [disabled]="order.status === 'IN_PROCESS' || order.status === 'CANCELED' || order.status === 'PROCESSED'"
                (click)="setStatusForOrder(order, 'IN_PROCESS')"
                mat-menu-item
        >
          <mat-icon color="accent">engineering</mat-icon>
          <span>Mark as In Progress</span>
        </button>

        <button [disabled]="order.status === 'PROCESSED' || order.status === 'CANCELED'"
                (click)="setStatusForOrder(order, 'PROCESSED')"
                mat-menu-item
        >
          <mat-icon color="accent">done_outline</mat-icon>
          <span>Mark as Processed</span>
        </button>

        <button [disabled]="order.status === 'CANCELED'  || order.status === 'PROCESSED'"
                (click)="setStatusForOrder(order, 'CANCELED')"
                mat-menu-item
        >
          <mat-icon color="accent">cancel</mat-icon>
          <span>Mark as Canceled</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
