<div style="margin-top: 16px">
  <div fxFlex="1 1 auto" fxLayoutAlign="start center" style="padding-left: 16px">
    <button mat-button mat-raised-button color="accent" (click)="openAddDiscountDialog()">Add new discount</button>
  </div>

  <mat-form-field>
    <mat-label>Search</mat-label>
    <input [(ngModel)]="searchField" matInput value="" #searchFieldElement>
  </mat-form-field>

  <mat-paginator (page)="pageEvent = $event; fetchDiscounts()"
                 [length]="length"
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize">
  </mat-paginator>
</div>

<table [dataSource]="discounts" class="mat-elevation-z8"
       mat-table
       matSort matSortActive="name" matSortDirection="asc" matSortDisableClear
>
  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
    <td mat-cell *matCellDef="let discount"> {{discount.createdAt | date}} </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let discount"> {{discount.name}} </td>
  </ng-container>

  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Value</th>
    <td mat-cell *matCellDef="let discount"> {{discount.value * 100 | number: '1.0-0'}}%</td>
  </ng-container>

  <ng-container matColumnDef="durationSec">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
    <td mat-cell *matCellDef="let discount"> {{discountService.durationFormat(discount)}} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef style="text-align: right; padding-right: 2.5%;">Actions</th>
    <td mat-cell *matCellDef="let discount">
      <div fxLayoutAlign="end center">
        <button mat-icon-button (click)="openEditDiscountDialog(discount)" matTooltip="Edit discount">
          <mat-icon color="accent">edit</mat-icon>
        </button>

        <button mat-icon-button (click)="removeDiscount(discount.id)" matTooltip="Delete discount">
          <mat-icon color="accent">delete</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


