import { AfterViewInit, Component, ElementRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from '../../entity/Product';

@Component({
  selector: 'app-image-gallery-modal',
  templateUrl: './image-gallery-modal.html',
  styleUrls: ['./image-gallery-modal.sass'],
})
export class ImageGalleryModalComponent implements AfterViewInit {

  public product: Product;
  public activeSlideIndex = 0;

  public readonly captionBlockId = 'caption';
  public readonly activeSlideClassName = 'active';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: Product,
    private readonly elementRef: ElementRef,
  ) {
    this.product = this.data;
  }

  public ngAfterViewInit(): void {
    this.openGallery();
  }

  public goToSlideByIndex(index: number): void {
    this.activeSlideIndex = index;
    this.openGallery();
  }

  public openGallery(): void {
    const slidesClassName = 'slide-' + this.product?.id;
    const relatedImagesClassName = 'related-image-' + this.product?.id;

    const slideHTMLElements = this.elementRef.nativeElement.getElementsByClassName(slidesClassName);
    const relatedImageHTMLElements = this.elementRef.nativeElement.getElementsByClassName(relatedImagesClassName);
    const captionHTMLElement = this.elementRef.nativeElement.querySelector('#' + this.captionBlockId);

    const slides = Array.from(slideHTMLElements as HTMLCollectionOf<HTMLElement>);
    const relatedImages = Array.from(relatedImageHTMLElements as HTMLCollectionOf<HTMLImageElement>);

    this.checkActiveSlideBoundaries(slides.length);
    this.clearActiveSlides(slides, relatedImages);
    this.setActiveSlide(slides, relatedImages);

    const activeImageHTMLElement = this.elementRef.nativeElement.getElementsByClassName(this.activeSlideClassName)[0];
    activeImageHTMLElement.scrollIntoView({ behavior: 'smooth' });

    captionHTMLElement.innerHTML = relatedImages[this.activeSlideIndex].alt || '';
  }

  public onNextClick(): void {
    this.activeSlideIndex += 1;
    this.openGallery();
  }

  public onPreviousClick(): void {
    this.activeSlideIndex -= 1;
    this.openGallery();
  }

  public isOnlyOneImageExist(): boolean {
    return this.product.images.length === 1;
  }

  private clearActiveSlides(slides: HTMLElement[], relatedImages: HTMLImageElement[]): void {
    slides.forEach((slide) => slide.style.display = 'none');
    relatedImages.forEach((relatedImage) =>
      relatedImage.className = relatedImage.className.replace(' ' + this.activeSlideClassName, ''),
    );
  }

  private setActiveSlide(slides: HTMLElement[], relatedImages: HTMLImageElement[]): void {
    slides[this.activeSlideIndex].style.display = 'flex';
    relatedImages[this.activeSlideIndex].className += ' ' + this.activeSlideClassName;
  }

  private checkActiveSlideBoundaries(upperBoundary: number): void {
    if (this.activeSlideIndex >= upperBoundary) {
      this.activeSlideIndex = 0;
    } else if (this.activeSlideIndex < 0) {
      this.activeSlideIndex = upperBoundary - 1;
    }
  }

}
