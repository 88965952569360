<h1 mat-dialog-title class="modal-title">Return Order</h1>
<mat-dialog-content>
    <p class="modal-text">Select the items to return:</p>
    <mat-selection-list>
        <mat-list-option *ngFor="let item of order.items" [value]="item" (click)="toggleSelection(item)">
            <div class="list-item">
                <div class="order-item">
                    <div class="item-image-container">
                        <img [src]="item.productImageUrl || '../../assets/default-product.png'"
                             [alt]="item.productName"
                             class="item-image">
                    </div>
                    <div class="item-info">
                        <span>{{ item.product.name }}</span>
                        <span>Quantity - {{ item.quantity }}</span>
                        <div class="price">
                            <span>{{ item.value }}</span>
                            <app-coin type="silver"></app-coin>
                        </div>
                    </div>
                </div>
                <div class="return-quantity" *ngIf="item.quantity > 1">
                    <span class="return-quantity-text">Return Quantity</span>
                    <mat-form-field class="quantity-input">
                        <input matInput type="number"
                               [ngModel]="quantityMap[item.id] || 1"
                               (ngModelChange)="updateQuantityToReturn(item, $event)"
                               [min]="1" [max]="item.quantity"
                               #quantityInput="ngModel"
                               required>
                        <mat-error *ngIf="quantityInput.invalid">
                            <span *ngIf="quantityInput.errors?.required">required</span>
                            <span *ngIf="quantityInput.errors?.min">min: 1</span>
                            <span *ngIf="quantityInput.errors?.max">max: {{ item.quantity }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-list-option>
    </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions class="action-buttons">
    <button mat-button mat-raised-button color="accent" (click)="cancel()">Cancel</button>
    <button mat-button mat-raised-button class="submit-button"
            (click)="submitPartialReturn()"
            [disabled]="!hasSelectedItems() || areAllItemsSelected() || !areAllQuantitiesValid()">
        Return Selected Items
    </button>
    <button mat-button mat-raised-button color="warn"
            (click)="submitFullReturn()">
        Return Full Order
    </button>
</mat-dialog-actions>