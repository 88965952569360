<div class="container">
  <div [hidden]="isImageLoaded" class="loading-image">
    <app-loading-text></app-loading-text>
  </div>
  <div *ngFor="let slide of slides; let i = index">
    <img class="slide" alt="Slide" *ngIf="i === currentSlide"
         [hidden]="!isImageLoaded"
         [src]="slide.url"
         (load)="onImageLoaded()"
         @carouselAnimation />
  </div>
  <div *ngIf="!slides.length">
    <img class="slide" alt="Slide"
         [hidden]="!isImageLoaded"
         [src]="defaultImageScr"
         (load)="onImageLoaded()" />
  </div>
  <div class="remove-control" *ngIf="isDeletionAllowed && slides.length">
    <button mat-icon-button matTooltip="Remove image">
      <mat-icon color="primary" class="remove-icon" (click)="removeImage()">
        delete
      </mat-icon>
    </button>
  </div>
  <button class="control prev" *ngIf="isMultipleImages() && isImageLoaded" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <button class="control next" *ngIf="isMultipleImages() && isImageLoaded" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
</div>

