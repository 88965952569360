import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../components/loading/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { TransferGoldModalComponent } from '../../modals/transfer-gold-modal/transfer-gold-modal.component';
import { UserManagementService } from '../../services/user-management.service';
import { UserService } from '../../services/user.service';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'app-home',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.sass']
})
export class WalletComponent implements OnInit {

  public silver: number | null = null;
  public gold: number | null = null;

  constructor(
    private readonly walletService: WalletService,
    private readonly modalService: MatDialog,
    private readonly userManagementService: UserManagementService,
    private readonly userService: UserService,
    private readonly loadingService: LoadingService,
  ) { }

  public ngOnInit(): void {
    this.fetchCoinsCount();
  }

  public fetchCoinsCount(): void {
    this.walletService.getInfo()
      .subscribe({
        next: (res) => {
          this.silver = res.silver;
          this.gold = res.gold;
        },
        complete: () => this.loadingService.hideSpinner(),
      });
  }

  public openTransferPopup(): void {
    this.userManagementService.getUsersSummary('', 1000, 0, 'name,asc')
      .subscribe((res) => {

        const dialogRef = this.modalService.open(TransferGoldModalComponent, {
          data: {
            users: res.data.filter(user => user.id !== this.userService.user?.id),
            coins: this.gold,
          },
          minWidth: 500
        });

        dialogRef.afterClosed().subscribe(transferData => {
          if (transferData) {
            this.loadingService.showSpinner();
            this.walletService.transferCoins(transferData).subscribe(() => this.fetchCoinsCount());
          }
        });
      });
  }

}

