<form (ngSubmit)="onFormSubmit(productForm.value)" #formElement
      [formGroup]="productForm"
      fxLayout="column"
      id="{{product && product.id ? 'editProductForm' : 'newProductForm'}}">

  <mat-form-field color="accent">
    <mat-label>Name</mat-label>
    <input formControlName="name" matInput>
    <mat-error *ngIf="isInValid('name')">Required and has to be less than 100 characters</mat-error>
  </mat-form-field>

  <mat-form-field color="accent">
    <mat-label>Price</mat-label>
    <input formControlName="price" matInput min="0" type="number">
    <mat-error *ngIf="isInValid('price')">Has to be 0-{{maxProductPrice}}</mat-error>
  </mat-form-field>

  <mat-form-field color="accent">
    <mat-label>Quantity</mat-label>
    <input formControlName="quantity" matInput min="0" type="number">
    <mat-error *ngIf="isInValid('quantity')">Has to be 1-1000</mat-error>
  </mat-form-field>

  <mat-form-field color="accent">
    <mat-label>Description</mat-label>
    <textarea formControlName="description" matInput></textarea>
    <mat-error *ngIf="isInValid('description')">Required and has to be less than 255 characters</mat-error>
  </mat-form-field>

  <mat-form-field color="discount">
    <mat-label>Discount</mat-label>
    <mat-select formControlName="discount" [compareWith]="isDiscountsEquals"
                (selectionChange)="enableDatepicker(isDelayedPublicationVisible())">
      <mat-option [ngClass]="{'current-discount': !product?.productDiscount?.discount?.id}"
      >
        None
      </mat-option>
      <mat-option *ngFor="let discount of discounts"
                  [ngClass]="{'current-discount': discount.id === product?.productDiscount?.discount?.id}"
                  [value]="discount">
        <app-discount-short-info [discount]="discount"></app-discount-short-info>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="isDelayedPublicationVisible()">
    <mat-checkbox formControlName="isDelayedPublication"
                  (change)="enableDatepicker(productForm.get('isDelayedPublication')?.value)">
      Set discount publication time
    </mat-checkbox>

    <mat-form-field color="accent" style="margin-top: 5px">
      <input matInput
             formControlName="discountStartDate"
             placeholder="Publication time"
             [ngxMatDatetimePicker]="$any(picker)"
             [min]="minimalDiscountStartDate">
      <button type="button" mat-icon-button matSuffix [disabled]="productForm.get('discountStartDate')?.disabled"
              (click)="picker.open(); $event.stopPropagation();">
        <mat-icon>today</mat-icon>
      </button>
      <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
    </mat-form-field>
  </ng-container>

  <button mat-button mat-raised-button color="accent" type="submit" [disabled]="productForm.invalid">Save</button>
</form>



