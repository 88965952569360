import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import {
  CreateDeleteRewardGroupUser,
  RewardGroup,
  UpdateRewardGroup,
  UpdateRewardGroupForm,
} from 'src/app/entity/RewardGroup';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { SelectableUser } from 'src/app/entity/SelectableUser';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-reward-group',
  templateUrl: './reward-group.html',
  styleUrls: ['./reward-group.sass']
})
export class RewardGroupComponent implements OnInit {

  @Input()
  public rewardGroup!: RewardGroup;

  @Input()
  public users!: SelectableUser[];

  @Output()
  public save = new EventEmitter<UpdateRewardGroupForm>();

  @Output()
  public delete = new EventEmitter<string>();

  public readonly displayedColumns = ['image', 'name', 'email', 'actions'];
  public dataSourceUsers = new MatTableDataSource<SelectableUser>();

  public usersInSelector!: SelectableUser[];
  public form!: UntypedFormGroup;

  private deletedParticipateIds: string[] = [];
  private addedParticipatesIds: string[] = [];

  public readonly pageSizeOptions = [5, 10, 25, 100];
  public countUserRecord = 0;
  public pageSize = 10;
  public pageIndex = 0;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly modalService: MatDialog,
  ) { }

  public ngOnInit(): void {
    this.dataSourceUsers.data = this.getPaginatedDataSource();
    this.usersInSelector = this.getUsersAreNotInGroup();
    this.form = this.getForm();
  }

  public onSelectUserIds(userIds: string[]): void {
    this.addedParticipatesIds = userIds;
    this.dataSourceUsers.data = this.getPaginatedDataSource();
  }

  public onDeleteGroup(groupId: string): void {
    const dialogRef = this.modalService.open(ConfirmModalComponent);

    dialogRef.afterClosed().subscribe((userConfirmAction) => {
      if (userConfirmAction) {
        this.delete.emit(groupId);
      }
    });
  }

  public onDeleteParticipate(id: string): void {
    const isWillBeAdded = this.checkUserWillBeAdded(id);

    if (isWillBeAdded) {
      this.addedParticipatesIds = this.addedParticipatesIds.filter((participateId) => participateId !== id);
      this.usersInSelector = this.toggleUserInSelect(id);
      this.dataSourceUsers.data = this.getPaginatedDataSource();
    } else {
      this.deletedParticipateIds.push(id);
    }
  }

  public cancelDeletePlayer(id: string): void {
    this.deletedParticipateIds = this.deletedParticipateIds.filter((userId) => userId !== id);
  }

  public checkUserWillBeDeleted(id: string): boolean {
    return this.deletedParticipateIds.includes(id);
  }

  public checkUserWillBeAdded(id: string): boolean {
    return this.addedParticipatesIds.includes(id);
  }

  public handlePage(pageEvent: PageEvent): void {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.dataSourceUsers.data = this.getPaginatedDataSource();
  }

  public identify(index: number, user: SelectableUser): string {
    return user.id;
  }

  public handleClickSave(): void {
    const { name, gold, silver, comment, day, month, isIncludeAllUsers } = this.form.controls;

    const updateRewardGroup: UpdateRewardGroup = {
      id: this.rewardGroup.id,
      name: name.value,
      gold: gold.value,
      silver: silver.value,
      day: day.value,
      month: month.value,
      comment: comment.value,
      isIncludeAllUsers: isIncludeAllUsers.value,
    };

    const createRewardGroupUsers: CreateDeleteRewardGroupUser[] = this.addedParticipatesIds.map((userId) => ({
      groupId: this.rewardGroup.id,
      userId,
    }));

    const deleteRewardGroupUsers: CreateDeleteRewardGroupUser[] = this.deletedParticipateIds.map((userId) => ({
      groupId: this.rewardGroup.id,
      userId,
    }));

    const updateRewardGroupForm: UpdateRewardGroupForm = {
      ...updateRewardGroup,
      addUsers: createRewardGroupUsers,
      deleteUsers: deleteRewardGroupUsers,
    };

    this.save.emit(updateRewardGroupForm);
  }

  private toggleUserInSelect(userId: string): SelectableUser[] {
    return this.usersInSelector.map((user) => {
      if (user.id === userId) {
        user.selected = !user.selected;
      }

      return user;
    });
  }

  private getPaginatedDataSource(): SelectableUser[] {
    const dataSource = this.getDataSource();
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;

    this.countUserRecord = dataSource.length;

    return dataSource.slice(startIndex, endIndex);
  }

  private getDataSource(): SelectableUser[] {
    const usersFromGroup = this.rewardGroup.participants.map((participant) => participant.user);
    const addedUsers = this.users.filter((user) => this.addedParticipatesIds.includes(user.id));

    return [...usersFromGroup, ...addedUsers];
  }

  private getUsersAreNotInGroup(): SelectableUser[] {
    const userIds = this.dataSourceUsers.data.map((user) => user.id);
    const filtered = this.users.filter((user) => !userIds.includes(user.id));

    return filtered.map((user) => ({ ...user }));
  }

  private getForm(): UntypedFormGroup {
    return this.formBuilder.group({
      name: [
        this.rewardGroup.name,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
        ]
      ],
      silver: [
        this.rewardGroup.silver,
        [
          Validators.required,
          Validators.min(0),
          Validators.max(3000),
        ],
      ],
      gold: [
        this.rewardGroup.gold,
        [
          Validators.required,
          Validators.min(0),
          Validators.max(3000),
        ],
      ],
      comment: [
        this.rewardGroup.comment,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(250),
        ],
      ],
      day: [
        this.rewardGroup.day,
        [
          Validators.min(1),
          Validators.max(31),
          Validators.required,
        ],
      ],
      month: [
        this.rewardGroup.month,
        [
          Validators.min(1),
          Validators.max(12),
          Validators.required,
        ]
      ],
      isIncludeAllUsers: [
        this.rewardGroup.isIncludeAllUsers,
        [
          Validators.required,
        ]
      ],
    });
  }

}
