<h1 mat-dialog-title style="text-align: center">Transfer
  <app-coin type="gold"></app-coin>
</h1>
<div mat-dialog-content fxLayout="column" style="overflow: visible">

  <form (ngSubmit)="onFormSubmit()"
        [formGroup]="transferForm"
        fxLayout="column"
        id="transferForm">

    <app-user-select label="User"
                     [users]="users"
                     (selectedUserIds)="onSelectUserIds($event)">
    </app-user-select>

    <mat-form-field color="accent">
      <mat-label>Gold amount</mat-label>
      <input formControlName="coins" matInput type="number" [max]="maxCoinsAmount" min="0">
      <mat-error *ngIf="maxCoinsAmount === 0">You don't have coins to send</mat-error>
      <mat-error *ngIf="isInValid('coins') && maxCoinsAmount > 0">Has to be in range 1-{{maxCoinsAmount}}</mat-error>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label>Comment</mat-label>
      <textarea style="max-height: 10em" formControlName="comment" matInput></textarea>
      <mat-error *ngIf="isInValid('comment')">Has to be less than 255 characters long</mat-error>
    </mat-form-field>
  </form>
</div>
<mat-checkbox color="accent" [(ngModel)]="transferForm.value.anonymously">Anonymously</mat-checkbox>
<mat-dialog-actions align="center">
  <button mat-button mat-raised-button color="accent" mat-dialog-close>Cancel</button>
  <button form="transferForm"
          color="accent"
          mat-button
          mat-raised-button
          type="submit"
          [disabled]="transferForm.invalid || !selectedUserId">Send
  </button>
</mat-dialog-actions>
