<div>

  <div matDialogTitle>
    Add discount

    <div fxFlex="1 1 auto"></div>

    <button mat-button mat-icon-button (click)="closeModal(false)" color="accent"
            matTooltip="Close batch update modal window">
      <mat-icon autofocus class="quantity-icon" color="accent">clear</mat-icon>
    </button>

  </div>
  <form #formElement
        [formGroup]="productForm"
        fxLayout="column">

    <mat-form-field color="discount">
      <mat-label>Discount</mat-label>
      <mat-select formControlName="discount" [compareWith]="compareDiscounts">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let discount of modalData.discounts" [value]="discount">
          <app-discount-short-info [discount]="discount"></app-discount-short-info>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="this.productForm.get('discount')?.value" style="margin-bottom: 5px">

      <mat-checkbox style="margin-bottom: 10px" (change)="toggleEmail($event)"
                    [disabled]="false">Send email
        notification
      </mat-checkbox>

      <mat-checkbox [checked]="isPublicationDelayEnabled" (change)="disableDatepicker()">
        Set discount publication time
      </mat-checkbox>

      <mat-form-field color="accent" style="margin-top: 5px; width: 100%">
        <input matInput
               formControlName="discountStartDate"
               [placeholder]="this.batchUpdateService.isProductsForBatchUpdateEmpty()
                                ? 'Select products to update'
                                : 'Publication time'"
               [ngxMatDatetimePicker]="$any(picker)"
               [min]="minDate">
        <button type="button" mat-icon-button matSuffix [disabled]="!isPublicationDelayEnabled"
                (click)="picker.open(); $event.stopPropagation();">
          <mat-icon>today</mat-icon>
        </button>
        <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
      </mat-form-field>

    </div>

    <button mat-button mat-raised-button color="accent" type="submit"
            (click)="onBatchUpdateSubmit()"
    >
      Save
    </button>
  </form>
</div>


