<app-loading-spinner></app-loading-spinner>

<mat-toolbar color="accent" *ngIf="isUserLoggedIn">
  <button (click)="goToHomePage()"
          fxLayoutAlign="center center"
          class="logo"
          mat-button
          matTooltip="Go to Home page"
  >
    Ubic&#8288;<mat-icon color="primary">monetization_on</mat-icon>&#8288;in
  </button>

  <div class="version">{{appVersion}}</div>

  <button mat-button color="primary"
          *ngIf="canShowBackButton()"
          (click)="back()">
    <mat-icon color="primary">west</mat-icon>
    Back
  </button>
  <div fxFlex="1 1 auto" fxLayoutAlign="center center"></div>

  <div class="user-info_transparent" (click)="goToWalletPage()" aria-label="Wallet page button"
       matTooltip="Go to Wallet page"
       style="cursor: pointer">
    <span style="font-size: 0.8em">
      {{walletService.silver}}
      <app-coin type="silver" size="big"></app-coin>
    </span>
  </div>

  <div class="user-info_transparent horizontal-gap" aria-label="Cart button" matTooltip="Check your cart"
       (click)="openCartModal()">
    <span class="user-cart">
      <mat-icon color="primary" matBadgeColor="primary" matBadgeSize="small"
                [matBadge]="cartService.getItemsCount() || null">
      shopping_cart
    </mat-icon>
    </span>
  </div>

  <img class="user-info_transparent" [mat-menu-trigger-for]="menu"
       [src]="userImageUrl$ | async"
       matTooltip="Open menu"
       alt="User image"
       height="32px"
       style="border-radius: 50%; margin: 0 8px;"
       onerror="this.onerror=null;this.src='../../assets/person-white-36dp.svg';">

  <mat-menu #menu>
    <button (click)="goToAdminPage()" *ngIf="isUserAdmin" aria-label="Admin page button" mat-menu-item>
      <mat-icon color="primary">build</mat-icon>
      <span>Go to Admin page</span>
    </button>

    <button (click)="openFeedbackModal()"
            aria-label="Feedback button"
            mat-menu-item>
      <mat-icon color="primary">bug_report</mat-icon>
      <span>Send feedback</span>
    </button>

    <button (click)="logOut()" aria-label="Admin page button" mat-menu-item>
      <mat-icon color="primary">login</mat-icon>
      <span>Log Out</span>
    </button>
  </mat-menu>
</mat-toolbar>


<main>
  <nav mat-tab-nav-bar *ngIf="isAdminPage()">
    <a mat-tab-link *ngFor="let link of adminLinks"
       [routerLink]="link.url"
       [routerLinkActive]="activeLink.name"
       (click)="changeActivePage(link)"
       [active]="activeLink.url === link.url"> {{link.name}} </a>
  </nav>
  <router-outlet></router-outlet>
</main>
