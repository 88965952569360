
<div class="wrapper">
    <button mat-button color="accent" mat-raised-button (click)="handleOpenCreateGroupModal()">
        Create new group
    </button>
    <div class="reward-group-wrapper">
        <app-reward-group *ngFor="let rewardGroup of rewardGroups"
            [rewardGroup]="rewardGroup"
            [users]="users"
            (save)="handleGroupUpdate($event)"
            (delete)="handleGroupDelete($event)"
            >
        </app-reward-group>
    </div>
</div>