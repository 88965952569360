import { CartService } from '../services/cart.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CartResolver implements Resolve<void> {

  constructor(private readonly cartService: CartService) { }

  public resolve(): void {
    this.cartService.initCart();
  }

}
