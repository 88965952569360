<div (click)="closeOptions()" *ngIf="isOptionsOpened" class="overlay"></div>

<div class="select">
  <mat-form-field color="accent" style="width: 100%">
    <mat-label>{{label}}</mat-label>

    <input class="select__input"
           #input matInput type="text"
           (focus)="openOptions()"
           (focusout)="onInputFocusOut()"
           [formControl]="inputControl">
    <mat-error *ngIf="!selectedUsers.length || selectedUser">Please select user</mat-error>

    <button *ngIf="inputControl.value && isOptionsOpened" matSuffix mat-icon-button aria-label="Clear"
            (click)="inputControl.setValue(''); filterUsers()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <div class="select-panel" *ngIf="isOptionsOpened" [@transformPanel]>
    <app-user-select-option matRipple *ngIf="isMultipleSelectionEnabled && !filter"
                            [isAllSelected]="isAllSelected"
                            [isSelectAllEnabled]="true"
                            [isMultipleSelectionEnabled]="isMultipleSelectionEnabled"
                            (click)="toggleAll()">
    </app-user-select-option>
    <app-user-select-option matRipple *ngFor="let user of filteredUsers | async"
                            [user]="user"
                            [isMultipleSelectionEnabled]="isMultipleSelectionEnabled"
                            (click)="toggle(user)">
    </app-user-select-option>
  </div>
</div>
