import * as moment from 'moment';
import { Discount } from '../entity/Discount';
import { DiscountSummary } from '../entity/DiscountSummary';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { ProductDiscountSummary } from '../entity/ProductDiscountSummary';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  public discountTimer = timer(0, 1000);

  constructor(private readonly httpClient: HttpClient) { }

  public onDiscountTimeUpdate(callback: () => void): Subscription {
    return this.discountTimer.subscribe(callback);
  }

  public getDiscounts(query: string, size: number, page: number, sort: string): Observable<{ data: Discount[], totalCount: number }> {
    const url = environment.API_URL.concat('/discount');
    const params = new HttpParams()
      .set('query', query)
      .set('size', size.toString(10))
      .set('page', page.toString(10))
      .set('sort', sort);

    return this.httpClient.get<{ data: Discount[], totalCount: number }>(url, { headers: new HttpHeaders(), params });
  }

  public getDiscount(id: string): Observable<Discount> {
    const url = environment.API_URL.concat(`/discount/${id}`);

    return this.httpClient.get<Discount>(url, { headers: new HttpHeaders() });
  }

  public updateDiscount(id: string, discount: DiscountSummary): Observable<Discount> {
    const url = environment.API_URL.concat(`/discount/${id}`);

    return this.httpClient.put<Discount>(url, discount, { headers: new HttpHeaders() });
  }

  public createDiscount(discount: DiscountSummary): Observable<Discount> {
    const url = environment.API_URL.concat('/discount');

    return this.httpClient.post<Discount>(url, discount, { headers: new HttpHeaders() });
  }

  public removeDiscount(id: string): Observable<void> {
    const url = environment.API_URL.concat(`/discount/${id}`);

    return this.httpClient.delete<void>(url, { headers: new HttpHeaders() });
  }

  public getPriceWithDiscount(price: number, productDiscount: ProductDiscountSummary, isUserAdmin?: boolean): number {
    const discount = productDiscount?.discount;
    if (discount && productDiscount) {
      if (this.getIsDiscountEnabled(productDiscount) || isUserAdmin) {
        return Math.round(price - price * discount.value);
      }
    }

    return price;
  }

  public getIsDiscountPublished(productDiscount: ProductDiscountSummary): boolean {
    return moment().isAfter(productDiscount.discountStartDate);
  }

  public getIsDiscountExpired(productDiscount: ProductDiscountSummary): boolean {
    return this.calculateTimeLeft(productDiscount) < 0;
  }

  public durationFormat(discount: Discount): string {
    if (discount.isInfinity) {
      return Number.POSITIVE_INFINITY.toString();
    }

    let result = '';
    if (discount.duration?.months !== 0) {
      result += `${discount.duration?.months} Months `;
    }
    if (discount.duration?.days !== 0) {
      result += `${discount.duration?.days} Days `;
    }
    if (discount.duration?.hours !== 0) {
      result += `${discount.duration?.hours} Hours`;
    }

    return result;
  }

  public getFormattedTimeLeft(productDiscount: ProductDiscountSummary): string {
    return this.timeFormat(this.calculateTimeLeft(productDiscount));
  }

  public getIsDiscountEnabled(productDiscount: ProductDiscountSummary): boolean {
    if (!productDiscount || !productDiscount.discount) {
      return false;
    }

    if (productDiscount.discount.isInfinity || !this.getIsDiscountPublished(productDiscount)) {
      return true;
    }

    return this.calculateTimeLeft(productDiscount) >= 0;
  }

  public getFormattedPublishDate(date: Date): string {
    return moment(date).format('DD.MM HH:mm');
  }

  public getPercentValue(discount: DiscountSummary): string {
    return Math.trunc(discount.value * 100) + '%';
  }

  private timeFormat(seconds: number): string {
    if (seconds < 0) {
      return 'Ended';
    }

    seconds = Math.trunc(seconds);
    const minutes = Math.trunc(seconds / 60);
    const hours = Math.trunc(minutes / 60);
    const days = Math.trunc(hours / 24);

    const finalMonths = Math.trunc(days / 30);
    const finalSeconds = seconds % 60;
    const finalMinutes = minutes % 60;
    const finalHours = hours % 24;
    const finalDays = days % 30;

    if (finalMonths >= 3) {
      return finalMonths + ' Months';
    }

    if (hours >= 24) {
      return finalDays + finalMonths * 30 + ' Days';
    }

    return (finalHours < 10 ? '0' + finalHours : finalHours) + ':'
      + (finalMinutes < 10 ? '0' + finalMinutes : finalMinutes) + ':'
      + (finalSeconds < 10 ? '0' + finalSeconds : finalSeconds);
  }

  private calculateTimeLeft(productDiscount: ProductDiscountSummary): number {
    if (!productDiscount.discount.duration) {
      return Number.POSITIVE_INFINITY;
    }
    const duration = productDiscount.discount.duration;

    const startDate = moment(productDiscount.discountStartDate);
    const endDate = startDate
      .add(duration.hours, 'hours')
      .add(duration.days, 'days')
      .add(duration.months, 'months');

    // 5 spare seconds
    return endDate.unix() - moment().unix() - 5;
  }

}
