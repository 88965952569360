import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../entity/Order';
import { OrderItemCreating } from '../entity/OrderItem';
import { ReturnRequest } from '../entity/ReturnRequest';
import { environment } from '../../environments/environment';

@Injectable()
export class OrderService {

  constructor(private readonly httpClient: HttpClient) { }

  public getOrders(query: string, size: number, page: number, sort: string): Observable<{ data: Order[], totalCount: number }> {
    const url = environment.API_URL.concat('/order');
    const params = new HttpParams()
      .set('query', query)
      .set('size', size.toString(10))
      .set('page', page.toString(10))
      .set('sort', sort);

    return this.httpClient.get<{ data: Order[], totalCount: number }>(url, { headers: new HttpHeaders(), params });
  }

  public getOrdersSummary(query: string, size: number, page: number, sort: string): Observable<{ data: Order[], totalCount: number }> {
    const url = environment.API_URL.concat('/order/summary');
    const params = new HttpParams()
      .set('query', query)
      .set('size', size.toString(10))
      .set('page', page.toString(10))
      .set('sort', sort);

    return this.httpClient.get<{ data: Order[], totalCount: number }>(url, { headers: new HttpHeaders(), params });
  }

  public createOrder(order: { comment: string, items: OrderItemCreating[] }
  ): Observable<Order> {
    const url = environment.API_URL.concat('/order');

    return this.httpClient.post<Order>(url, order, { headers: new HttpHeaders() });
  }

  public getOrder(id: string): Observable<Order> {
    const url = environment.API_URL.concat(`/order/${id}`);

    return this.httpClient.get<Order>(url, { headers: new HttpHeaders() });
  }

  public removeOrder(id: string): Observable<void> {
    const url = environment.API_URL.concat(`/order/${id}`);

    return this.httpClient.delete<void>(url, { headers: new HttpHeaders() });
  }

  public setStatusForOrder(id: string, status: {
    comment: string;
    status: 'NEW' | 'IN_PROCESS' | 'PROCESSED' | 'CANCELED' | 'RETURNED';
  }): Observable<void> {
    const url = environment.API_URL.concat(`/order/${id}/status`);

    return this.httpClient.put<void>(url, status, { headers: new HttpHeaders() });
  }

  public cancelOrder(id: string, data: { comment: string }): Observable<Order> {
    const url = environment.API_URL.concat(`/order/${id}/cancelling`);

    return this.httpClient.put<Order>(url, data, { headers: new HttpHeaders() });
  }

  public returnOrder(id: string, returnRequest: ReturnRequest): Observable<Order> {
    const url = environment.API_URL.concat(`/order/${id}/return`);

    return this.httpClient.put<Order>(url, returnRequest);
  }

}