<div gdColumns="repeat( auto-fill, minmax(300px, 1fr))" gdGap="32px 32px" style="padding: 32px; overflow: hidden;" *ngIf="product">
  <mat-card class="product-wrapper">
    <mat-card-header fxLayoutAlign="center center">
      <mat-card-title>Update product info</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-edit-product [discounts]="discounts"
                        [product]="product"
                        (updateProductEvent)="onProductUpdate($event); userViewProductComponent.discountInit();"
                        (changeProductEvent)="onPreviewProductChanged($event); previewProductComponent.discountInit();"
      >
      </app-edit-product>
    </mat-card-content>
  </mat-card>


  <mat-card class="product-wrapper">
    <mat-card-header fxLayoutAlign="center center">
      <mat-card-title>Add new image</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div fxLayoutAlign="center center">
        <div gdColumns="1fr 1fr" gdGap="16px">
          <input (change)="fileChangeEvent($event)" id="file" style="display: none" type="file" accept=".jpg, .jpeg, .png"/>
          <label class="mat-buttton mat-raised-button mat-accent" for="file">
            Select new image
          </label>
          <button (click)="uploadImage()"
                  *ngIf="croppedImage"
                  color="accent"
                  fxFlexAlign="center"
                  mat-raised-button>
            Upload
          </button>
        </div>
      </div>

      <div *ngIf="imageChangedEvent" class="image-wrapper">
        <div class="loading-image" [hidden]="isImageLoaded">
          <app-loading-text></app-loading-text>
        </div>
        <image-cropper (imageCropped)="imageCropped($event)"
                       (imageLoaded)="onImageLoaded()"
                       [imageChangedEvent]="imageChangedEvent"
                       [containWithinAspectRatio]="true"
                       [aspectRatio]="4/3"
                       [hidden]="!isImageLoaded"
                       [resizeToWidth]="1200"
                       format="png"
        ></image-cropper>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="product-wrapper">
    <mat-card-header fxLayoutAlign="center center">
      <mat-card-title>Preview</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-product #previewProductComponent
                   [product]="previewProduct"
                   [isCartButtonVisible]="false"
                   [isEditButtonVisible]="false"
                   [isRemoveButtonVisible]="false"
                   [isImageDeletingAllowed]="true"
                   [isUserAdmin]="true"
                   (deleteProductImage)="removeImageById($event)"
      ></app-product>
    </mat-card-content>
  </mat-card>

  <mat-card class="product-wrapper">
    <mat-card-header fxLayoutAlign="center center">
      <mat-card-title>User view</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-product #userViewProductComponent
                   [product]="product"
                   [isCartButtonReadonly]="true"
                   [isLikeButtonReadonly]="true"
                   [isImagesViewEnabled]="true"
      ></app-product>
    </mat-card-content>
  </mat-card>
</div>
