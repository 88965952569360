import { DashboardCharts } from '../entity/DashboardCharts';
import { DashboardInfo } from '../entity/DashboardInfo';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class DashboardService {

  constructor(private readonly httpClient: HttpClient) { }

  public getInfo(): Observable<DashboardInfo> {
    const url = environment.API_URL.concat('/dashboard');

    return this.httpClient.get<DashboardInfo>(url, { headers: new HttpHeaders() });
  }

  public getCharts(): Observable<DashboardCharts> {
    const url = environment.API_URL.concat('/dashboard/charts');

    return this.httpClient.get<DashboardCharts>(url, { headers: new HttpHeaders() });
  }

}
