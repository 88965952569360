import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ProductImage } from '../../entity/ProductImage';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.html',
  styleUrls: ['./image-slider.sass'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
      transition('* => void', [
        animate('300ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ImageSliderComponent implements OnChanges {

  @Input()
  public slides: ProductImage[] = [];
  @Input()
  public isDeletionAllowed = false;
  @Output()
  public deleteImage = new EventEmitter<string>();

  public currentSlide = 0;
  public isImageLoaded = false;

  public readonly defaultImageScr = '../../assets/default-product.png';

  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.slides) this.currentSlide = 0;
  }

  public onPreviousClick(): void {
    const previous = this.currentSlide - 1;

    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
  }

  public onNextClick(): void {
    const next = this.currentSlide + 1;

    this.currentSlide = next === this.slides.length ? 0 : next;
  }

  public removeImage(): void {
    const targetImageId = this.slides[this.currentSlide].id;
    const previousSlideIndex = this.currentSlide - 1;

    this.currentSlide = previousSlideIndex < 0
      ? this.slides.length - 2
      : previousSlideIndex;

    this.deleteImage.emit(targetImageId);
  }

  public isMultipleImages(): boolean {
    return this.slides.length > 1;
  }

  public onImageLoaded(): void {
    this.isImageLoaded = true;
  }

}
