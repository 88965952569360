import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { ChangeOrderStatusModalComponent } from '../../modals/change-order-staus-modal/change-order-status-modal.component';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { LoadingService } from '../loading/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Order } from '../../entity/Order';
import { OrderService } from '../../services/order.service';
import { PageEvent } from '@angular/material/paginator';
import { ReturnOrderModalComponent } from '../../modals/return-order-modal/return-order-modal.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.html',
  styleUrls: ['./orders.sass']
})
export class OrdersComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort)
  private readonly sort!: MatSort;
  @ViewChild('searchFieldElement')
  private readonly searchFieldElement!: ElementRef;

  public searchField = '';
  public length = 100;
  public pageEvent!: PageEvent;
  public orders = new MatTableDataSource<Order>();

  public readonly pageSize = 10;
  public readonly pageSizeOptions = [5, 10, 25, 100];
  public readonly displayedColumns = [
    'user',
    'items',
    'totalQuantity',
    'totalValue',
    'comment',
    'adminComment',
    'createdAt',
    'status',
    'actions'
  ];

  constructor(
    private readonly orderService: OrderService,
    private readonly modalService: MatDialog,
    private readonly cartService: CartService,
    private readonly loadingService: LoadingService,
  ) {
    this.cartService.onOrderSubmit.subscribe(() => this.fetchOrders());
  }

  public ngOnInit(): void {
    this.fetchOrders();
  }

  public ngAfterViewInit(): void {
    this.orders.sort = this.sort;
    this.sort.sortChange.subscribe(() => this.fetchOrders());
  }

  public fetchOrders(query = this.searchField || '',
    pageSize = this.pageEvent?.pageSize || 10,
    page = this.pageEvent?.pageIndex || 0,
    sort = this.sort ? `${this.sort.active},${this.sort.direction}` : 'updatedAt,desc'
  ): void {
    this.loadingService.showSpinner();
    this.orderService.getOrders(query, pageSize, page, sort).subscribe({
      next: (orders) => {
        this.length = orders.totalCount;
        this.orders.data = orders.data;
      },
      complete: () => this.loadingService.hideSpinner(),
    });
  }

  public deleteOrder(order: Order): void {
    const dialogRef = this.modalService.open(ConfirmModalComponent);

    dialogRef.afterClosed().subscribe((userConfirmAction) => {
      if (userConfirmAction) {

        this.orderService.removeOrder(order.id).subscribe(() =>
          this.orders.data = this.orders.data.filter(o => o.id !== order.id),
        );
      }
    });
  }

  public setStatusForOrder(order: Order, status: 'NEW' | 'IN_PROCESS' | 'PROCESSED' | 'CANCELED' | 'RETURNED'): void {
    if (status === 'CANCELED') {
      const dialogRef = this.modalService.open(ChangeOrderStatusModalComponent, {
        width: '400px',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (!result.confirmed || !(result.comment = result.comment.trim()).length) {
          return;
        }

        this.loadingService.showSpinner();
        this.orderService.setStatusForOrder(order.id, { comment: result.comment, status }).subscribe({
          next: () => {
            order.status = status;
            order.adminComment = result.comment;
          },
          complete: () => this.loadingService.hideSpinner(),
        });
      });
    }

    else {
      this.loadingService.showSpinner();
      this.orderService.setStatusForOrder(order.id, { comment: '', status }).subscribe({
        next: () => order.status = status,
        complete: () => this.loadingService.hideSpinner(),
      });
    }
  }

  public returnOrder(order: Order): void {
    const dialogRef = this.modalService.open(ReturnOrderModalComponent, {
      width: '600px',
      data: order
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;

      this.loadingService.showSpinner();
      this.orderService.returnOrder(order.id, result.data).subscribe({
        next: (res: Order) => {
          order.status = res.status;
          order.items = res.items;
        },
        error: (err) => {
          this.loadingService.hideSpinner();
          console.error('Error returning order: ', err);
        },
        complete: () => this.loadingService.hideSpinner()
      });
    });
  }

}

