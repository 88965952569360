import { Component, Inject, OnInit } from '@angular/core';
import { Discount } from '../../entity/Discount';
import { DiscountFormModel, DiscountSummary } from 'src/app/entity/DiscountSummary';
import { DiscountService } from '../../services/discount.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-discount-management-modal',
  templateUrl: './discount-management-modal.component.html',
  styleUrls: ['./discount-management-modal.component.sass']
})
export class DiscountManagementModalComponent implements OnInit {

  public addDiscountForm: UntypedFormGroup;
  public isEditing = Boolean(this.discount);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly discount: Discount,
    private readonly dialogRef: MatDialogRef<DiscountManagementModalComponent>,
    private readonly discountService: DiscountService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly snackBarService: MatSnackBar,
  ) {
    this.addDiscountForm = formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100)
        ]
      ],
      value: [
        '',
        [
          Validators.required,
          Validators.min(1),
          Validators.max(100)
        ]
      ],
      isInfinity: [
        true, []
      ],
      months: [
        0,
        [
          Validators.required,
          Validators.min(0),
          Validators.max(99)
        ]
      ],
      days: [
        0,
        [
          Validators.required, Validators.min(0),
          Validators.max(29)
        ]
      ],
      hours: [
        0,
        [
          Validators.required,
          Validators.min(0),
          Validators.max(23)
        ]
      ]
    });
  }

  public ngOnInit(): void {
    if (this.discount) {
      this.addDiscountForm.patchValue({
        name: this.discount?.name,
        value: Math.trunc(this.discount?.value * 100),
        isInfinity: this.discount?.isInfinity,
        months: this.discount?.duration?.months || 0,
        days: this.discount?.duration?.days || 0,
        hours: this.discount?.duration?.hours || 0
      });
    }

    this.enableDuration(!this.addDiscountForm.get('isInfinity')?.value);
  }

  public isInValid(fieldName: string): boolean {
    const field = this.addDiscountForm.get(fieldName);

    return field ? field.invalid && field.dirty : false;
  }

  public onFormSubmit(formValue: DiscountFormModel): void {
    const discount: DiscountSummary = {
      name: formValue.name,
      value: Math.trunc(formValue.value) / 100,
      isInfinity: formValue.isInfinity,
      duration: {
        hours: Math.trunc(formValue.hours),
        days: Math.trunc(formValue.days),
        months: Math.trunc(formValue.months)
      },
      isActive: true
    };

    if (this.isEditing) {
      this.discountService.updateDiscount(this.discount.id, discount).subscribe(() =>
        this.snackBarService.open('Discount updated successfully', undefined, { duration: 5000 }),
      );
    } else {
      this.discountService.createDiscount(discount).subscribe(() =>
        this.snackBarService.open('Discount created successfully', undefined, { duration: 5000 }),
      );
    }

    this.dialogRef.close(true);
  }

  public enableDuration(isEnabled: boolean): void {
    const durationControlNames = ['months', 'days', 'hours'];
    if (isEnabled) {
      for (const controlName of durationControlNames) {
        this.addDiscountForm.get(controlName)?.enable();
      }
    } else {
      for (const controlName of durationControlNames) {
        this.addDiscountForm.get(controlName)?.disable();
      }
    }
  }

}
