import { CoinsAdding } from '../../entity/UserCoinAudit';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '../../entity/User';

@Component({
  selector: 'app-transfer-gold-modal',
  templateUrl: './add-coins-modal.html',
  styleUrls: ['./add-coins-modal.sass']
})
export class AddCoinsModalComponent {

  public users = this.data.users;
  public selectedUserIds!: string[];
  public addCoinsForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { users: User[] },
    private readonly dialogRef: MatDialogRef<AddCoinsModalComponent>,
    private readonly formBuilder: UntypedFormBuilder,
  ) {
    this.addCoinsForm = this.formBuilder.group({
      coins: [0, [Validators.min(1),
        Validators.max(500),
        Validators.required]],
      comment: ['', [Validators.maxLength(255), Validators.required]],
      type: ['SILVER', [Validators.required]],
    });
  }

  public isInValid(fieldName: string): boolean {
    const field = this.addCoinsForm.get(fieldName);

    return field ? field.invalid && field.dirty : false;
  }

  public onFormSubmit(): void {
    const coins = this.selectedUserIds.map((userId: string) => {
      return {
        userId,
        coins: this.addCoinsForm.value.coins,
        type: this.addCoinsForm.value.type
      };
    });

    this.closeModal({ comment: this.addCoinsForm.value.comment, coins });
  }

  public onSelectUserIds(userIds: string[]): void {
    this.selectedUserIds = userIds;
  }

  private closeModal(transferData: { comment: string, coins: CoinsAdding[] }): void {
    this.dialogRef.close(transferData);
  }

}

