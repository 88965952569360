import { CreateDeleteRewardGroupUser } from '../entity/RewardGroup';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class RewardGroupUserService {

  constructor(
        private readonly httpClient: HttpClient,
  ) {}

  public createMany(rewardGroupUsers: CreateDeleteRewardGroupUser[]): Observable<void> {
    const url = environment.API_URL.concat('/reward-group-users/');

    return this.httpClient.post<void>(url, rewardGroupUsers);
  }

  public deleteMany(rewardGroupUsers: CreateDeleteRewardGroupUser[]): Observable<void> {
    const url = environment.API_URL.concat('/reward-group-users/');

    return this.httpClient.delete<void>(url, {
      body: rewardGroupUsers,
    });
  }

}
