<mat-card [ngClass]="{ product: true }" fxLayout="column">
  <div class="product__header"
       [ngClass]="{ 'product__header_tag-padding': product.productDiscount?.discount?.isActive,
                    'product_disabled': !product.isActive }"
  >
    <div class="product__select" *ngIf="isUserAdmin && isBatch && product.quantity > 0">
      <mat-checkbox (change)="toggleBatchUpdate($event)"
                    [checked]="isAddToBatchUpdate"></mat-checkbox>
    </div>
    <div class="product__title">{{product.name}}</div>
  </div>

  <div class="product__image-container" [ngClass]="{ 'product_disabled': !product.isActive }">
    <span class="product__big-fat-label" *ngIf="product.quantity < 1">SOLD</span>

    <app-discount-tag *ngIf="product.productDiscount?.discount?.isActive"
                      [isUserAdmin]="isUserAdmin"
                      [productDiscount]="product.productDiscount" class="product__discount">
      Discount
    </app-discount-tag>
    <span class="product__right-label color-borders pointer-events-none">
      <div class="product__discount-price">
        <div *ngIf="product.productDiscount?.discount?.isActive"
             class="product__discount-price_new">
          {{discountService.getPriceWithDiscount(product.price, product.productDiscount, isUserAdmin)}}
        </div>
        <div [ngClass]="{ 'product__discount-price_old':product.productDiscount?.discount?.isActive}">{{product.price}}</div>
      </div>
      <mat-icon>monetization_on</mat-icon>
    </span>

    <span class="product__left-label color-borders pointer-events-none">
      <mat-icon>shopping_bag</mat-icon>
      {{product.quantity}} left
    </span>

    <div [ngClass]="{ 'product__image-container_sold': isProductSold() }"
         class="product__image-controls"
    >
      <div *ngIf="isImagesViewEnabled" [hidden]="isImageLoaded" class="loading-image">
        <app-loading-text></app-loading-text>
      </div>
      <div>
          <img *ngIf="isImagesViewEnabled" class="full-width pointer image-scale"
               [alt]="product.name"
               [hidden]="!isImageLoaded"
               [src]="getPrimaryImageSrc()"
               (click)="onImageClick()"
               (load)="onImageLoaded()" />
      </div>
      <button *ngIf="isImagesViewEnabled && isImageLoaded"
              mat-icon-button class="preview-btn pointer-events-none"
              (click)="onImageClick()"
      >
        <mat-icon color="primary">zoom_in</mat-icon>
      </button>
      <app-image-slider *ngIf="!isImagesViewEnabled" class="full-width"
                        [slides]="product.images || []"
                        [isDeletionAllowed]="isImageDeletingAllowed"
                        (deleteImage)="onRemoveImage($event)"
      ></app-image-slider>
    </div>
  </div>

  <mat-card-content class="product__description" fxFlex="1 1 auto"
                    [ngClass]="{ 'product_disabled': !product.isActive }"
  >
    <p>{{product.description}}</p>
  </mat-card-content>


  <mat-card-actions class="product__actions" *ngIf="!isActionsHidden">
    <button *ngIf="isUserAdmin && isEditButtonVisible" mat-icon-button matTooltip="Edit product">
      <mat-icon (click)="editProduct(product)" color="accent">edit</mat-icon>
    </button>

    <button *ngIf="isUserAdmin && isRemoveButtonVisible" mat-icon-button matTooltip="Remove product">
      <mat-icon (click)="removeProduct(product.id)" color="accent">delete</mat-icon>
    </button>

    <button *ngIf="product.isActive && isUserAdmin" mat-icon-button matTooltip="Disable product">
      <mat-icon (click)="disableProduct(product.id)" color="accent">visibility_off
      </mat-icon>
    </button>

    <button *ngIf="!product.isActive && isUserAdmin" mat-icon-button matTooltip="Enable product">
      <mat-icon (click)="enableProduct(product.id)" color="accent">visibility
      </mat-icon>
    </button>

    <button *ngIf="!isUserAdmin" mat-icon-button
            [matTooltip]="isLiked ? 'Remove like' : 'Add like'"
            [disabled]="isLikeButtonReadonly"
            [ngClass]="{ 'product_disabled': !product.isActive }"
            (click)="likeProduct(product.id)"
    >
      <mat-icon [matBadge]="product.likes.length"
                [matBadgeDisabled]="!product.likes.length"
                matBadgeColor="primary"
                matBadgeSize="small"
                color="accent"
      >
        {{isLiked ? 'favorite' : 'favorite_border'}}
      </mat-icon>
    </button>

    <div fxFlex="1 1 auto"></div>

    <button mat-icon-button matTooltip="Add product to cart"
            *ngIf="(!cartService.isProductInCart(product) && product.quantity > 0) && isCartButtonVisible && !isUserAdmin"
            [disabled]="isCartButtonReadonly"
            [ngClass]="{ 'product_disabled': !product.isActive }"
            (click)="cartService.addToCart(product)"
    >
      <mat-icon color="accent">add_shopping_cart</mat-icon>
    </button>

    <button mat-icon-button matTooltip="Remove product from cart"
            *ngIf="cartService.isProductInCart(product) && isCartButtonVisible && !isUserAdmin"
            [disabled]="isCartButtonReadonly"
            [ngClass]="{ 'product_disabled': !product.isActive }"
            (click)="cartService.removeFromCart(product)"
    >
      <mat-icon color="accent">remove_shopping_cart</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
