<h1 mat-dialog-title style="text-align: center" fxLayoutAlign="center center">Add coins</h1>
<div mat-dialog-content fxLayout="column">

  <form (ngSubmit)="onFormSubmit()"
        [formGroup]="addCoinsForm"
        fxLayout="column"
        id="addCoinsForm">

    <app-user-select label="User"
                     [users]="users"
                     [isMultipleSelectionEnabled]="true"
                     (selectedUserIds)="onSelectUserIds($event)">
    </app-user-select>

    <div>
      <mat-label>Coins type</mat-label>
      <div fxLayoutAlign="center center">
        <mat-button-toggle-group name="type" aria-label="Coin type" formControlName="type">
          <mat-button-toggle value="GOLD">
            <app-coin type="gold"></app-coin>
          </mat-button-toggle>
          <mat-button-toggle value="SILVER">
            <app-coin type="silver"></app-coin>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <mat-error *ngIf="isInValid('type')">Please select coin type</mat-error>
    </div>


    <mat-form-field color="accent">
      <mat-label>{{addCoinsForm.value.type === "SILVER" ? 'Silver' : 'Gold'}} amount</mat-label>
      <input formControlName="coins" matInput type="number" [max]="3000" min="0">
      <mat-error *ngIf="isInValid('coins')">Has to be in range 1-3000</mat-error>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label>Comment</mat-label>
      <textarea style="max-height: 10em" formControlName="comment" matInput></textarea>
      <mat-error *ngIf="isInValid('comment')">Required and has to be less then 255 characters</mat-error>
    </mat-form-field>
  </form>
</div>
<mat-dialog-actions align="center">
  <button mat-button mat-raised-button color="accent" mat-dialog-close>Cancel</button>
  <button form="addCoinsForm"
          mat-button
          mat-raised-button
          color="accent"
          type="submit"
          [disabled]="addCoinsForm.invalid || !selectedUserIds?.length">Send
  </button>
</mat-dialog-actions>

