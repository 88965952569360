import { AddRewardGroupModalComponent } from 'src/app/modals/add-reward-group-modal/add-reward-group-modal.component';
import { Component, OnInit } from '@angular/core';
import { CreateRewardGroup } from './../../entity/RewardGroup';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { RewardGroup, UpdateRewardGroupForm } from 'src/app/entity/RewardGroup';
import { RewardGroupService } from './../../services/reward-group.service';
import { RewardGroupUserService } from 'src/app/services/reward-group-user.service';
import { UserManagementService } from './../../services/user-management.service';
import { UserSummary } from 'src/app/entity/UserSummary';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-reward-groups',
  templateUrl: './reward-groups.html',
  styleUrls: ['./reward-groups.sass']
})
export class RewardGroupsComponent implements OnInit {

  public rewardGroups!: RewardGroup[];
  public users!: UserSummary[];

  constructor(
    private readonly loadingService: LoadingService,
    private readonly modalService: MatDialog,
    private readonly userManagementService: UserManagementService,
    private readonly rewardGroupService: RewardGroupService,
    private readonly rewardGroupUserService: RewardGroupUserService,
  ) { }

  public ngOnInit(): void {
    this.fetchGroupsAndUsers();
  }

  public handleGroupUpdate(updateRewardGroupForm: UpdateRewardGroupForm): void {
    this.loadingService.showSpinner();
    const { addUsers, deleteUsers } = updateRewardGroupForm;

    forkJoin({
      updateGroup: this.rewardGroupService.update(updateRewardGroupForm),
      addedUsers: this.rewardGroupUserService.createMany(addUsers),
      deleteUsers: this.rewardGroupUserService.deleteMany(deleteUsers),
    }).subscribe({
      complete: () => {
        this.fetchGroupsAndUsers();
        this.loadingService.hideSpinner();
      },
      error: (err) => {
        console.error('Error occured', err);
        this.loadingService.hideSpinner();
      },
    });
  }

  public handleOpenCreateGroupModal(): void {
    const dialogRef = this.modalService.open(AddRewardGroupModalComponent);

    dialogRef.afterClosed().subscribe((createRewardGroup: CreateRewardGroup | undefined) => {
      if (createRewardGroup) {
        this.handleGroupCreate(createRewardGroup);
      }
    });
  }

  public handleGroupCreate(createRewardGroup: CreateRewardGroup): void {
    this.loadingService.showSpinner();
    this.rewardGroupService.create(createRewardGroup).subscribe({
      complete: () => {
        this.fetchGroupsAndUsers();
        this.loadingService.hideSpinner();
      },
      error: (err) => {
        console.error('Error occured', err);
        this.loadingService.hideSpinner();
      },
    });
  }

  public handleGroupDelete(groupId: string): void {
    this.loadingService.showSpinner();
    this.rewardGroupService.delete(groupId).subscribe({
      complete: () => {
        this.fetchGroupsAndUsers();
        this.loadingService.hideSpinner();
      },
      error: (err) => {
        console.error('Error occured', err);
        this.loadingService.hideSpinner();
      },
    });
  }

  private fetchGroupsAndUsers(): void {
    this.loadingService.showSpinner();
    const usersPerPage = 1000;
    const pageIndex = 0;

    forkJoin({
      users: this.userManagementService.getUsersSummary('', usersPerPage, pageIndex, 'name,asc'),
      rewardGroups: this.rewardGroupService.getAll(),
    }).subscribe({
      next: ({ users, rewardGroups }) => {
        this.users = users.data;
        this.rewardGroups = rewardGroups;
      },
      complete: () => {
        this.loadingService.hideSpinner();
      },
      error: (err) => {
        console.error('Error occurred:', err);
        this.loadingService.hideSpinner();
      }
    });
  }

}