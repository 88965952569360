<div id="imageGalleryModal" class="modal">
  <button mat-icon-button class="close-btn silver-hover"
          [mat-dialog-close]="true"
          [disableRipple]="true"
  >
    <mat-icon color="primary">close</mat-icon>
  </button>
  <div class="content" *ngIf="product.images.length; else withoutImages">
    <div class="image-section">
      <div class="slide" *ngFor="let image of product.images; let i = index"
           [ngClass]="'slide-' + product.id"
      >
        <div class="numbertext">{{ i + 1 }} / {{ product.images.length }}</div>
        <div class="image-wrapper">
          <button mat-icon-button class="prev-btn silver-hover"
                  *ngIf="!isOnlyOneImageExist()"
                  [disableRipple]="true"
                  (click)="onPreviousClick()"
          >
            <mat-icon color="primary">arrow_back_ios</mat-icon>
          </button>
          <button mat-icon-button class="next-btn silver-hover"
                  *ngIf="!isOnlyOneImageExist()"
                  [disableRipple]="true"
                  (click)="onNextClick()"
          >
            <mat-icon color="primary">arrow_forward_ios</mat-icon>
          </button>
          <img class="full-width" [src]="image.url" [alt]="product.name" />
        </div>
      </div>
      <div class="caption-container">
        <p [id]="captionBlockId"></p>
      </div>
    </div>
    <div class="related-images">
      <div class="column" *ngFor="let image of product.images; let i = index">
        <img class="pointer full-width related-image"
             [ngClass]="'related-image-' + product.id"
             [alt]="product.name"
             [src]="image.url"
             (click)="goToSlideByIndex(i)"
        />
      </div>
    </div>
  </div>
  <ng-template #withoutImages>
    <div class="content">
      <div class="image-section">
        <div class="slide" [ngClass]="'slide-' + product.id">
          <div class="numbertext">1 / 1</div>
          <div class="image-wrapper">
            <img class="full-width" src="assets/default-product.png" [alt]="product.name" />
          </div>
        </div>
        <div class="caption-container">
          <p [id]="captionBlockId"></p>
        </div>
      </div>
      <div class="related-images">
        <div class="column">
          <img class="pointer full-width related-image"
               src="assets/default-product.png"
               [ngClass]="'related-image-' + product.id"
               [alt]="product.name"
          />
        </div>
      </div>
    </div>
  </ng-template>
</div>
