<div class="control-elements">
  <div fxLayoutAlign="start center" fxFlex="1 1 auto">
    <div *ngIf="isAdminView && disableBatchUpdateButton()" class="control-element__button">
      <button mat-button mat-raised-button color="accent" (click)="openBatchUpdateModal()">Add discount</button>
    </div>

    <div *ngIf="isAdminView && products.length" class="control-element__button"
         fxFlex="1 1 auto">
      <button mat-button mat-raised-button
              [innerText]="getTextForProductSelectionToggle()"
              (click)="toggleAllSelectProduct()"
      ></button>
    </div>
  </div>

  <div *ngIf="isAdminView && products.length">
      <mat-checkbox [(ngModel)]="isOnlyAvailable" (change)="fetchProducts()">
          Show only available products
        </mat-checkbox>
  </div>

  <mat-form-field class="control-element">
    <mat-label>Sort by:</mat-label>
    <mat-select [(value)]="sortTypeSelected" (selectionChange)="fetchProducts()">
      <mat-option [value]="sortTypes.NAMES_A_Z">Names (a-z)</mat-option>
      <mat-option [value]="sortTypes.NAMES_Z_A">Names (z-a)</mat-option>
      <mat-option [value]="sortTypes.COUNTS">Quantity</mat-option>
      <mat-option [value]="sortTypes.PRICE_LOW_TO_HIGH">Price Low to High</mat-option>
      <mat-option [value]="sortTypes.PRICE_HIGH_TO_LOW">Price High to Low</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="control-element">
    <mat-label>Search</mat-label>
    <input matInput value="" #searchFieldElement
           [(ngModel)]="searchField"
           (ngModelChange)="onStartingSearchProduct()" />
  </mat-form-field>

  <mat-paginator #paginator
                 (page)="fetchProducts()"
                 [length]="length"
                 [pageSize]="pageSize"
                 [pageSizeOptions]="pageSizeOptions">
  </mat-paginator>
</div>

<mat-divider></mat-divider>

<section gdColumns="repeat( auto-fill, minmax(300px, 1fr))" gdGap="32px 32px"
         style="overflow-x: hidden; padding: 32px">
  <mat-card *ngIf="isAdminView" class="new-product">
    <mat-card-header class="new-product__header">
      <mat-card-title class="new-product__title">Add new product</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <app-edit-product [discounts]="discounts" (newProductEvent)="addedNewProduct($event)"></app-edit-product>
    </mat-card-content>
  </mat-card>

  <app-product *ngFor="let product of products"
               [product]="product"
               [isUserAdmin]="isAdminView"
               [isBatch]="true"
               [isAddToBatchUpdate]="this.batchUpdateService.isAllProductSelected"
               [isImagesViewEnabled]="isImagesViewEnabled"
               (deleteProduct)="removeProduct($event)"
  ></app-product>
</section>

<mat-divider></mat-divider>

<a (click)="loadMoreItems(howManyProductsLeftToLoad(length, this.paginator.pageSize))"
   class="load-more"
   *ngIf="this.paginator.pageSize < length && products.length"
>
  <mat-icon [inline]="true" color="primary" style="font-size: 80px; line-height: 1;">refresh</mat-icon>
  <h1 style="margin: 0">Load {{howManyProductsLeftToLoad(length, this.paginator.pageSize)}} more items</h1>
</a>

<div *ngIf="!products.length && isLoaded && !isAdminView"
     style="margin-top: 48px"
     fxLayout="column"
     fxLayoutAlign="center center">
  <h2>We don't have such thing</h2>
  <img src="../../assets/sentiment_neutral.svg" alt="Neutral face" style="width: 80px;">
</div>
