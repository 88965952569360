<h1 mat-dialog-title>{{isEditing ? 'Edit discount' : 'Add new discount'}}</h1>
<mat-dialog-content>
  <form (ngSubmit)="onFormSubmit(addDiscountForm.value)"
        [formGroup]="addDiscountForm"
        fxLayout="column"
        id="addDiscountForm">


    <mat-form-field color="accent">
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput type="text">
      <mat-error *ngIf="isInValid('name')">Required and has to be < 100 characters</mat-error>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label>Value (%)</mat-label>
      <input formControlName="value" matInput type="number" min="0" max="100">
      <mat-error *ngIf="isInValid('value')">Required and has to be between 1-100</mat-error>
    </mat-form-field>

    <mat-checkbox formControlName="isInfinity"
                  (change)="enableDuration(!addDiscountForm.get('isInfinity')?.value)">
      Discount is infinite
    </mat-checkbox>

    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <mat-form-field color="accent" style="width: 30%">
        <mat-label>Months</mat-label>
        <input formControlName="months" matInput type="number" min="0" max="99">
        <mat-error *ngIf="isInValid('months')">Required and has to be < 100</mat-error>
      </mat-form-field>

      <mat-form-field color="accent" style="width: 30%">
        <mat-label>Days</mat-label>
        <input formControlName="days" matInput type="number" min="0" max="29">
        <mat-error *ngIf="isInValid('days')">Required and has to be < 30</mat-error>
      </mat-form-field>

      <mat-form-field color="accent" style="width: 30%">
        <mat-label>Hours</mat-label>
        <input formControlName="hours" matInput type="number" min="0" max="23">
        <mat-error *ngIf="isInValid('hours')">Required and has to be < 24</mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-button mat-raised-button color="accent" mat-dialog-close>Cancel</button>
  <button form="addDiscountForm"
          mat-button
          mat-raised-button
          color="accent"
          type="submit"
          [disabled]="addDiscountForm.invalid">Save
  </button>
</mat-dialog-actions>
