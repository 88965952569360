import { CommonModule } from '@angular/common';
import { LoadingService } from './loading.service';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LoadingTextComponent } from './loading-text/loading-text.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    LoadingTextComponent,
    LoadingSpinnerComponent,
  ],
  exports: [
    LoadingTextComponent,
    LoadingSpinnerComponent,
  ],
  imports: [CommonModule],
  providers: [LoadingService],
})
export class LoadingModule { }
