import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AddCoinsModalComponent } from './modals/add-coins-modal/add-coins-modal.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptorService } from './services/auth.interceptor.service';
import { BatchUpdateModalComponent } from './modals/batch-update-modal/batch-update-modal.component';
import { BatchUpdateProductService } from './services/batch-update-product.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CacheStorageService } from './services/cache.storage.service';
import { CartComponent } from './components/cart/cart.component';
import { CartModalComponent } from './modals/cart-modal/cart-modal.component';
import { CartService } from './services/cart.service';
import { CdkColumnDef } from '@angular/cdk/table';
import { ChangeOrderStatusModalComponent } from './modals/change-order-staus-modal/change-order-status-modal.component';
import { ChartsModule } from 'ng2-charts';
import { CoinComponent } from './components/coin/coin.component';
import { CommonModule } from '@angular/common';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardService } from './services/dashboard.service';
import { DiscountManagementModalComponent } from './modals/discount-management-modal/discount-management-modal.component';
import { DiscountShortInfoComponent } from './components/discount-short-info/discount-short-info.component';
import { DiscountTagComponent } from './components/discount-tag/discount-tag.component';
import { DiscountsComponent } from './pages/discounts/discounts.component';
import { EditProductComponent } from './components/edit-product/edit-product.component';
import { EmailService } from './services/email.service';
import { EmailsComponent } from './pages/emails/emails.component';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { FeedbackComponent } from './pages/feedbacks/feedback.component';
import { FeedbackModalComponent } from './modals/feedback/feedback-modal.component';
import { FeedbackService } from './services/feedback.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalStateService } from './services/global-state.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './pages/home/home.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageGalleryModalComponent } from './modals/image-gallery-modal/image-gallery-modal.component';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { LoadingModule } from './components/loading/loading.module';
import { ManageProductComponent } from './pages/manage-product/manage-product.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { OrderService } from './services/order.service';
import { OrdersComponent } from './components/orders/orders.component';
import { ProductComponent } from './components/product/product.component';
import { ProductImageService } from './services/product-image.service';
import { ProductService } from './services/product.service';
import { ProductsComponent } from './components/products/products.component';
import { RouterModule } from '@angular/router';
import { StorageService } from './services/storage.service';
import { TransferGoldModalComponent } from './modals/transfer-gold-modal/transfer-gold-modal.component';
import { UserManagementService } from './services/user-management.service';
import { UserOrdersComponent } from './components/user-orders/user-orders.component';
import { UserSelectComponent } from './components/user-select/user-select.component';
import { UserSelectOptionComponent } from './components/user-select/user-select-option/user-select-option.component';
import { UserService } from './services/user.service';
import { UserShortInfoComponent } from './components/user-short-info/user-short-info.component';
import { UserTransactionsComponent } from './components/user-transactions/user-transactions.component';
import { UsersManagementComponent } from './components/users-management/users-management.component';
import { WalletComponent } from './pages/wallet/wallet.component';
import { WalletService } from './services/wallet.service';
import { initKeycloak } from './services/auth.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ManageProductComponent,
    UsersManagementComponent,
    WalletComponent,
    ProductComponent,
    EditProductComponent,
    ProductsComponent,
    CartComponent,
    OrdersComponent,
    ErrorModalComponent,
    CartModalComponent,
    ChangeOrderStatusModalComponent,
    UserShortInfoComponent,
    CoinComponent,
    TransferGoldModalComponent,
    AddCoinsModalComponent,
    ConfirmModalComponent,
    UserOrdersComponent,
    FeedbackComponent,
    DashboardComponent,
    FeedbackModalComponent,
    EmailsComponent,
    UserTransactionsComponent,
    DiscountsComponent,
    DiscountManagementModalComponent,
    DiscountShortInfoComponent,
    DiscountTagComponent,
    BatchUpdateModalComponent,
    UserSelectComponent,
    UserSelectOptionComponent,
    ImageGalleryModalComponent,
    ImageSliderComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    ChartsModule,
    MatTableModule,
    MatMenuModule,
    RouterModule.forRoot([{
      path: 'redirect',
      component: AppComponent
    }]),
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDialogModule,
    MatTooltipModule,
    MatToolbarModule,
    MatTabsModule,
    MatListModule,
    MatSnackBarModule,
    ImageCropperModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MatRippleModule,
    KeycloakAngularModule,
    LoadingModule,
  ],
  providers: [
    GlobalStateService,
    // Shared
    UserService,
    StorageService,
    CacheStorageService,
    FeedbackService,
    ProductService,
    ProductImageService,
    UserManagementService,
    BatchUpdateProductService,
    DashboardService,
    CartService,
    OrderService,
    WalletService,
    EmailService,
    // Specific
    CdkColumnDef,
    { provide: APP_INITIALIZER, useFactory: initKeycloak, deps: [KeycloakService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
