import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

  public set<T>(key: string, value: T): void {
    if (!value) return;

    localStorage.setItem(key, JSON.stringify(value));
  }

  public get<T>(key: string): T | null {
    const data = localStorage.getItem(key);
    
    if (!data) return null;

    return JSON.parse(data);
  }

  public clear(): void {
    localStorage.clear();
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

}
