<section *ngIf="info"
         gdColumns="repeat( auto-fit, minmax(150px, 1fr))" gdGap="16px 16px" style="padding: 16px">
  <mat-card>
    <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon matTooltip="Count of users">supervisor_account</mat-icon>
      <span style="font-size: 16px; line-height: 24px"> {{info.countOfUsers}} Users</span>
    </div>
  </mat-card>

  <mat-card>
    <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon matTooltip="Count of Products">shopping_bag</mat-icon>
      <span style="font-size: 16px; line-height: 24px"> {{info.countOfProducts}} Products</span>
    </div>
  </mat-card>

  <mat-card>
    <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon matTooltip="Count of Orders">shopping_cart</mat-icon>
      <span style="font-size: 16px; line-height: 24px"> {{info.countOfOrders}} Orders</span>
    </div>
  </mat-card>

  <mat-card>
    <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon matTooltip="Count of Likes">favorite</mat-icon>
      <span style="font-size: 16px; line-height: 24px"> {{info.countOfLikes}} Likes</span>
    </div>
  </mat-card>

  <mat-card>
    <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon matTooltip="Count of Feedbacks">bug_report</mat-icon>
      <span style="font-size: 16px; line-height: 24px"> {{info.countOfFeedbacks}} Feedbacks</span>
    </div>
  </mat-card>

  <mat-card>
    <div fxFlex="1 1 auto" fxLayout="row">
      <app-coin type="gold"></app-coin>
      <span style="font-size: 16px; line-height: 24px"> {{info.totalGold}} Gold</span>
    </div>
  </mat-card>


  <mat-card>
    <div fxFlex="1 1 auto" fxLayout="row">
      <app-coin type="silver"></app-coin>
      <span style="font-size: 16px; line-height: 24px"> {{info.totalSilver}} Silver</span>
    </div>
  </mat-card>
</section>

<section *ngIf="chartsInfo"
         gdColumns="repeat( auto-fit, minmax(400px, 1fr))" gdGap="16px 16px" style="padding: 16px">
  <mat-card>
    <mat-card-title>Orders</mat-card-title>

    <canvas baseChart
            [data]="chartsInfo.orders.values"
            [labels]="chartsInfo.orders.labels"
            [chartType]="'pie'"
            [options]="{responsive: true}"
            [legend]="true">
    </canvas>

  </mat-card>

  <mat-card>
    <mat-card-title>Products</mat-card-title>

    <canvas baseChart
            [datasets]="productsStock.dataset"
            [labels]="productsStock.labels"
            [options]="productsStock.options"
            [plugins]="[]"
            [legend]="true"
            [chartType]="'bar'">
    </canvas>

  </mat-card>

  <mat-card>
    <mat-card-title>Likes</mat-card-title>

    <canvas baseChart
            [data]="productsLikes.data"
            [labels]="productsLikes.labels"
            [options]="productsLikes.options"
            [plugins]="[]"
            [legend]="true"
            [chartType]="'bar'">
    </canvas>

  </mat-card>
</section>
<section gdColumns="1fr 0.5fr" gdGap="16px 16px" style="padding: 16px">

  <mat-card *ngIf="this.users">
    <mat-card-title>User Activity</mat-card-title>

    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilterToUsers($event)" placeholder="" #usersFilter>
    </mat-form-field>

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="users" #sort1="matSort" matSort>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>User Name</th>
          <td mat-cell *matCellDef="let user"> {{user.name}} </td>
        </ng-container>

        <!-- Activity Column -->
        <ng-container matColumnDef="activity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Last activity</th>
          <td mat-cell *matCellDef="let user"> {{user.activity | date}} </td>
        </ng-container>

        <!-- Likes Column -->
        <ng-container matColumnDef="likes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Likes</th>
          <td mat-cell *matCellDef="let user">
            {{user.likes}}
          </td>
        </ng-container>

        <!-- Orders Column -->
        <ng-container matColumnDef="orders">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Orders</th>
          <td mat-cell *matCellDef="let user">
            {{user.orders}}
          </td>
        </ng-container>

        <!-- Silver Column -->
        <ng-container matColumnDef="silver">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Silver</th>
          <td mat-cell *matCellDef="let user">
            {{user.silver}}
          </td>
        </ng-container>

        <!-- Gold Column -->
        <ng-container matColumnDef="gold">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Gold</th>
          <td mat-cell *matCellDef="let user">
            {{user.gold}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="usersDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: usersDisplayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{usersFilter.value}}"</td>
        </tr>
      </table>
    </div>
  </mat-card>


  <mat-card *ngIf="this.topProducts">
    <mat-card-title>Top Ordered</mat-card-title>
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilterToOrders($event)" placeholder="" #ordersFilter>
    </mat-form-field>

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="topProducts" #sort2="matSort" matSort>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Product name</th>
          <td mat-cell *matCellDef="let product"> {{product.name}} </td>
        </ng-container>

        <ng-container matColumnDef="orders">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>orders</th>
          <td mat-cell *matCellDef="let product"> {{product.orders}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="ordersDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: ordersDisplayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{ordersFilter.value}}"</td>
        </tr>
      </table>
    </div>
  </mat-card>
</section>
