import { CreateRewardGroup } from './../entity/RewardGroup';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RewardGroup, UpdateRewardGroup } from '../entity/RewardGroup';
import { environment } from 'src/environments/environment';

@Injectable()
export class RewardGroupService {

  constructor(
    private readonly httpClient: HttpClient,
  ) {}

  public getAll(): Observable<RewardGroup[]> {
    const url = environment.API_URL.concat('/reward-groups');

    return this.httpClient.get<RewardGroup[]>(url);
  }

  public update(updateRewardGroup: UpdateRewardGroup): Observable<RewardGroup> {
    const url = environment.API_URL.concat(`/reward-groups/${updateRewardGroup.id}`);

    return this.httpClient.put<RewardGroup>(url, updateRewardGroup);
  }

  public create(createRewardGroup: CreateRewardGroup): Observable<RewardGroup> {
    const url = environment.API_URL.concat('/reward-groups');

    return this.httpClient.post<RewardGroup>(url, createRewardGroup);
  }

  public delete(groupId: string): Observable<void> {
    const url = environment.API_URL.concat(`/reward-groups/${groupId}`);

    return this.httpClient.delete<void>(url);
  }

}