import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { Discount } from '../../entity/Discount';
import { DiscountManagementModalComponent } from '../../modals/discount-management-modal/discount-management-modal.component';
import { DiscountService } from '../../services/discount.service';
import { LoadingService } from '../../components/loading/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.sass']
})
export class DiscountsComponent implements OnInit, AfterViewInit {

  @ViewChild('searchFieldElement')
  private readonly searchFieldElement!: ElementRef;
  @ViewChild(MatSort)
  private readonly sort!: MatSort;

  public searchField = '';
  public pageEvent!: PageEvent;
  public discounts = new MatTableDataSource<Discount>();
  public length = 100;

  public readonly displayedColumns = ['createdAt', 'name', 'value', 'durationSec', 'actions'];
  public readonly pageSize = 10;
  public readonly pageSizeOptions = [5, 10, 25, 100];

  constructor(
    public readonly discountService: DiscountService,
    private readonly modalService: MatDialog,
    private readonly snackBarService: MatSnackBar,
    private readonly loadingService: LoadingService,
  ) { }

  public ngOnInit(): void {
    this.fetchDiscounts();
  }

  public ngAfterViewInit(): void {
    this.discounts.sort = this.sort;
    this.sort.sortChange.subscribe(() => this.fetchDiscounts());
    fromEvent(this.searchFieldElement.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.fetchDiscounts();
        })
      )
      .subscribe();
  }

  public fetchDiscounts(query = this.searchField || '',
    pageSize = this.pageEvent?.pageSize || 10,
    page = this.pageEvent?.pageIndex || 0,
    sort = this.sort ? `${this.sort.active},${this.sort.direction}` : 'name,asc'
  ): void {
    this.loadingService.showSpinner();
    this.discountService.getDiscounts(query, pageSize, page, sort).subscribe({
      next: (discounts) => {
        this.length = discounts.totalCount;
        this.discounts.data = discounts.data;
      },
      complete: () => this.loadingService.hideSpinner(),
    });
  }

  public openAddDiscountDialog(): void {
    const dialogRef = this.modalService.open(DiscountManagementModalComponent, {
      minWidth: 600,
      minHeight: 350
    });

    dialogRef.afterClosed().subscribe((userConfirmAction) => {
      if (userConfirmAction) this.fetchDiscounts();
    });
  }

  public openEditDiscountDialog(discount: Discount): void {
    const dialogRef = this.modalService.open(DiscountManagementModalComponent, {
      minWidth: 600,
      minHeight: 350,
      data: discount
    });

    dialogRef.afterClosed().subscribe((userConfirmAction) => {
      if (userConfirmAction) this.fetchDiscounts();
    });
  }

  public removeDiscount(id: string): void {
    const dialogRef = this.modalService.open(ConfirmModalComponent);

    dialogRef.afterClosed().subscribe((userConfirmAction) => {
      if (userConfirmAction) {
        this.loadingService.showSpinner();
        this.discountService.removeDiscount(id).subscribe(() => {
          this.snackBarService.open('Discount was successfully removed', undefined, { duration: 5000 });
          this.fetchDiscounts();
        });
      }
    });
  }

}
