<div class="wallet__cards-container">
  <mat-card class="wallet__card">
    <mat-card-header fxLayoutAlign="center center">
      <mat-card-title fxLayoutAlign="center center">
        {{silver}}
        <app-coin type="silver"></app-coin>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>Silver coins -
        <app-coin type="silver"></app-coin>
        can be used only for purchasing our swag.
      </p>
      <p>You can't transfer them to another users.</p>
      <p>You can't exchange them for gold coins. </p>
      <p>You will get them on particular dates:</p>
      <ul>
        <li> National Holidays</li>
        <li> St. Valentine`s Day</li>
        <li> 8th of March (gold for guys, silver for girls)</li>
        <li> Unibrix Birthday ( 31st of March)</li>
        <li> Tester`s Day (9th of September)</li>
        <li> Developer`s Day ( 13th of September)</li>
        <li> HR`s Day ( 15th of September)</li>
        <li> Accountant Day (10th of November)</li>
        <li> St. Nicola`s Day (19th of December)</li>
        <li> Birthday (silver enrollment only for celebrating person)</li>
      </ul>
    </mat-card-content>
  </mat-card>

  <mat-card class="wallet__card">
    <mat-card-header fxLayoutAlign="center center">
      <mat-card-title fxLayoutAlign="center center">
        {{gold}}
        <app-coin type="gold"></app-coin>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>Gold coins -
        <app-coin type="gold"></app-coin>
        can be used only for transferring to another user.
      </p>
      <p>
        Gold coins, which you transferred to a friend, become
        <app-coin type="silver"></app-coin>
        silver coins for him.
      </p>
      <p>You can't buy things by those.</p>
      <p> You will get them only by personal activity: </p>
      <ul>
        <li>Attending a professional conference (Meetup)</li>
        <li>Taking training courses due to your professional position (or advanced English)</li>
        <li>Bringing a potential candidate</li>
        <li>Assisting in organizing events</li>
        <li>To come to the corporate event</li>
        <li>Mentoring a new employee</li>
        <li>Ideas and suggestions for improving the atmosphere in the office of your company</li>
      </ul>

      <div>
        <div fxFlex="1 1 auto"></div>
        <button (click)="openTransferPopup()"
                color="accent"
                mat-button
                mat-raised-button>
          <span> Send
            <app-coin type="gold"></app-coin>
            to a friend
          </span>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<mat-tab-group>
  <mat-tab label="Transactions">
    <app-user-transactions [isUserSelectorDisplayed]="false"></app-user-transactions>
  </mat-tab>

  <mat-tab label="Orders">
    <app-user-orders (cancelOrderEvent)="this.fetchCoinsCount()"></app-user-orders>
  </mat-tab>
</mat-tab-group>
