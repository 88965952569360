import { Injectable } from '@angular/core';

@Injectable()
export class LoadingService {

  public isLoading = false;

  public showSpinner(): void {
    this.isLoading = true;
  }

  public hideSpinner(): void {
    this.isLoading = false;
  }

}