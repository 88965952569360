import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { ChangeOrderStatusModalComponent } from '../../modals/change-order-staus-modal/change-order-status-modal.component';
import { LoadingService } from '../loading/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Order } from '../../entity/Order';
import { OrderService } from '../../services/order.service';
import { PageEvent } from '@angular/material/paginator';
import { throttle } from '../../decorators/throttle.decorator';
import { throttleDelayInMilliseconds } from 'src/app/constants/constants';

@Component({
  selector: 'app-user-orders',
  templateUrl: './user-orders.html',
  styleUrls: ['./user-orders.sass']
})
export class UserOrdersComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort)
  private readonly sort!: MatSort;
  @Output()
  public cancelOrderEvent = new EventEmitter();

  public orders = new MatTableDataSource<Order>();
  public length = 100;
  public pageSize = 5;
  public pageEvent!: PageEvent;

  public readonly pageSizeOptions = [5, 10, 25, 100];
  public readonly displayedColumns = [
    'createdAt',
    'items',
    'comment',
    'status',
    'totalValue',
    'cancel'
  ];

  constructor(
    private readonly orderService: OrderService,
    private readonly modalService: MatDialog,
    private readonly cartService: CartService,
    private readonly loadingService: LoadingService,
  ) {
    cartService.onOrderSubmit.subscribe(() => this.ngOnInit());
  }

  public ngOnInit(): void {
    this.fetchOrders();
  }

  public ngAfterViewInit(): void {
    this.orders.sort = this.sort;
    this.sort.sortChange.subscribe(() => this.fetchOrders());
  }

  @throttle(throttleDelayInMilliseconds)
  public fetchOrders(
    query = '',
    pageSize = this.pageEvent?.pageSize || 5,
    page = this.pageEvent?.pageIndex || 0,
    sort = this.sort ? `${this.sort.active},${this.sort.direction}` : 'updatedAt,desc'
  ): void {
    this.loadingService.showSpinner();
    this.orderService.getOrdersSummary(query, pageSize, page, sort).subscribe({
      next: (orders) => {
        this.length = orders.totalCount;
        this.orders.data = orders.data;
      },
      complete: () => this.loadingService.hideSpinner(),
    });
  }

  public cancelOrder(order: Order): void {
    const dialogRef = this.modalService.open(ChangeOrderStatusModalComponent, {
      data: false
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.confirmed) {
        this.loadingService.showSpinner();
        this.orderService.cancelOrder(order.id, { comment: result.comment }).subscribe((res: Order) => {
          order.status = res.status;
          order.comment = res.comment;
          this.cancelOrderEvent.emit();
        });
      }
    });
  }

}

