<section class="order-management">
  <div>
    <h2 fxFlex="1 1 auto"
        fxLayoutAlign="center center"
        style="margin: 0">
      Your Orders
    </h2>
    <mat-paginator (page)="pageEvent = $event; fetchOrders()"
                   [length]="length"
                   [pageSizeOptions]="pageSizeOptions"
                   [pageSize]="pageSize">
    </mat-paginator>
  </div>

  <br>

  <table [dataSource]="orders" class="mat-elevation-z8"
         mat-table
         matSort matSortActive="createdAt" matSortDirection="desc" matSortDisableClear>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 90px">Status</th>
      <td mat-cell *matCellDef="let order" style="width: 90px">
        <mat-icon *ngIf="order.status === 'NEW'" matTooltip="New order">child_friendly</mat-icon>
        <mat-icon *ngIf="order.status === 'IN_PROCESS'" matTooltip="Order is processing">engineering</mat-icon>
        <mat-icon *ngIf="order.status === 'PROCESSED'" matTooltip="Order is delivered">done_outline</mat-icon>
        <mat-icon *ngIf="order.status === 'CANCELED'" matTooltip="Order is canceled">cancel</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="totalValue">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
      <td mat-cell *matCellDef="let order">
        <div>
          <app-coin type="silver"></app-coin>
          {{order.totalValue}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="items">
      <th mat-header-cell *matHeaderCellDef style="width:50%; padding: 2px 10px">Items</th>
      <td mat-cell *matCellDef="let order">
        <div *ngFor="let item of order.items;">
          <span> {{item.productName}} - {{item.quantity}} </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Comment</th>
      <td mat-cell *matCellDef="let order" style="width:50%">{{order.comment}}</td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 90px">Date</th>
      <td mat-cell *matCellDef="let order" style="width: 90px; white-space: nowrap; padding-right: 12px">
        {{order.createdAt | date}}</td>
    </ng-container>

    <ng-container matColumnDef="cancel">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let order">
        <button #cancelButton [disabled]="order.status !== 'NEW'"
                mat-icon-button (click)="cancelOrder(order)">
          <mat-icon [ngClass]="{'cancel-button_disabled': cancelButton.disabled}" color="accent"
                    [matTooltip]="cancelButton.disabled ? '' : 'Cancel order'">undo</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</section>
