<mat-checkbox *ngIf="isMultipleSelectionEnabled"
              [checked]="isSelectAllEnabled ? isAllSelected : user?.selected || false"
              (click)="$event.preventDefault()">
</mat-checkbox>
<div style="width: 100%;">
  <ng-container *ngIf="!isSelectAllEnabled">
    <app-user-short-info style="width: 100%"
                         [user]="user">
    </app-user-short-info>
  </ng-container>

  <ng-container *ngIf="isSelectAllEnabled && isMultipleSelectionEnabled">
    <p class="text">Select All</p>
  </ng-container>
</div>
