import { Component, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.html',
  styleUrls: ['./error-modal.sass']
})
export class ErrorModalComponent {

  public error = (this.errorData as HttpErrorResponse).error || this.errorData;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly errorData: HttpErrorResponse | Error,
    private readonly dialogRef: MatDialogRef<ErrorModalComponent>,
  ) { }

  public closeModal(): void {
    this.dialogRef.close();
  }

}




