import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Order } from '../../entity/Order';
import { OrderItem } from '../../entity/OrderItem';
import { ReturnRequest } from '../../entity/ReturnRequest';

@Component({
  selector: 'app-return-order-modal',
  templateUrl: './return-order-modal.html',
  styleUrls: ['./return-order-modal.sass']
})
export class ReturnOrderModalComponent {

  public selectedItems: { item: OrderItem, quantityToReturn: number }[] = [];
  public quantityMap: { [itemId: string]: number } = {};

  constructor(
        public dialogRef: MatDialogRef<ReturnOrderModalComponent>,
        @Inject(MAT_DIALOG_DATA) public order: Order
  ) {}

  public toggleSelection(item: OrderItem): void {
    const isSelected = this.selectedItems.some(selected => selected.item.id === item.id);

    if (isSelected) {
      this.selectedItems = this.selectedItems.filter(selected => selected.item.id !== item.id);
    } else {
      this.selectedItems.push({ item, quantityToReturn: this.quantityMap[item.id] ?? 1 });
    }
  }

  public updateQuantityToReturn(item: OrderItem, quantityToReturn: number): void {
    this.quantityMap[item.id] = quantityToReturn;
    const selectedItem = this.selectedItems.find(selected => selected.item.id === item.id);

    if (selectedItem) {
      selectedItem.quantityToReturn = quantityToReturn;
    }
  }

  public areAllQuantitiesValid(): boolean {
    return this.areQuantitiesForSelectedItemsValid() && this.areQuantityMapForSelectedItemsValid();
  }

  public areQuantitiesForSelectedItemsValid(): boolean {
    return this.selectedItems.every(selectedItem =>
      selectedItem.quantityToReturn >= 1 &&
      selectedItem.quantityToReturn <= selectedItem.item.quantity
    );
  }

  public areQuantityMapForSelectedItemsValid(): boolean {
    return Object.entries(this.quantityMap).every(([itemId, quantity]) => {
      const selectedItem = this.selectedItems.find(selected => selected.item.id === itemId);

      return selectedItem ? quantity >= 1 && quantity <= selectedItem.item.quantity : true;
    });
  }

  public submitPartialReturn(): void {
    const partialReturnRequest: ReturnRequest = {
      items: this.selectedItems.map(selected => ({
        orderItemId: selected.item.id,
        quantityToReturn: selected.quantityToReturn
      }))
    };

    this.dialogRef.close({ data: partialReturnRequest });
  }

  public submitFullReturn(): void {
    const fullReturnRequest: ReturnRequest = {
      items: this.order.items.map(item => ({
        orderItemId: item.id,
        quantityToReturn: item.quantity
      }))
    };

    this.dialogRef.close({ data: fullReturnRequest });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public hasSelectedItems(): boolean {
    return this.selectedItems.length > 0;
  }

  public areAllItemsSelected(): boolean {
    return this.selectedItems.length === this.order.items.length &&
        this.selectedItems.every(selectedItem =>
          selectedItem.quantityToReturn === selectedItem.item.quantity
        );
  }

}
