<mat-card class="wrapper">
    <mat-card-content style="position: relative; display: flex; flex-direction: column; gap: 10px;">
        <button class="delete-button"
            mat-icon-button
            matTooltip="Delete Group"
            (click)="onDeleteGroup(rewardGroup.id)">
            <mat-icon color="primary">delete</mat-icon>
        </button>
        <form [formGroup]="form" class="coinInputs">
            <div class="form-section">
                <mat-form-field>
                    <mat-label>Group Name</mat-label>
                    <input formControlName="name" matInput>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Silver</mat-label>
                    <input type="number" formControlName="silver" min="0" matInput>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Gold</mat-label>
                    <input type="number" formControlName="gold" min="0" matInput>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Comment</mat-label>
                    <input formControlName="comment" matInput>
                </mat-form-field>
            </div>
            <div class="form-section">
                <mat-form-field>
                    <mat-label>Reward Day</mat-label>
                    <input type="number" formControlName="day" min="0" matInput>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Reward Month</mat-label>
                    <input type="number" formControlName="month" min="0" matInput>
                </mat-form-field>
                <div class="isIncludeAllUsersWrapper">
                    <mat-checkbox formControlName="isIncludeAllUsers">
                        The group includes all users
                    </mat-checkbox>
                </div>
            </div>
        </form>
        <div [style.display]="form.get('isIncludeAllUsers')?.value ? 'none' : 'block'">
            <div class="userSelectorWrapper">
                <ng-container *ngIf="usersInSelector.length; then userSelect; else allUsersAdded">
                </ng-container>
                <ng-template #userSelect>
                    <app-user-select
                    label="Add users to the group"
                    [isMultipleSelectionEnabled]="true"
                    [users]="usersInSelector"
                    (selectedUserIds)="onSelectUserIds($event)">
                    </app-user-select>
                </ng-template>
                <ng-template #allUsersAdded>
                    All available users are added to the group
                </ng-template>
            </div>
            <mat-paginator
                [length]="countUserRecord"
                [pageSizeOptions]="pageSizeOptions"
                [pageIndex]="pageIndex"
                [pageSize]="pageSize"
                (page)="handlePage($event)">
            </mat-paginator>
            <table [dataSource]="dataSourceUsers" class="mat-elevation-z8" mat-table [trackBy]="identify">
                <ng-container matColumnDef="image">
                    <th mat-header-cell *matHeaderCellDef>Image</th>
                    <td mat-cell *matCellDef="let user">
                        <img mat-card-avatar [src]="user.imageUrl" >
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let user"> {{ user.name }} </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let user"> {{ user.email }} </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let user">
                        <ng-container *ngIf="checkUserWillBeDeleted(user.id); then cancel; else delete">
                        </ng-container>
                        <ng-template #cancel>
                            <button mat-button color="primary" (click)="cancelDeletePlayer(user.id)">
                                <mat-icon color="primary">cancel</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template #delete>
                            <button mat-button color="primary" (click)="onDeleteParticipate(user.id)">
                                <mat-icon color="primary">delete</mat-icon>
                            </button>
                        </ng-template>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let user; columns: displayedColumns;"
                    [class.added-row]="checkUserWillBeAdded(user.id)"
                    [class.deleted-row]="checkUserWillBeDeleted(user.id)"
                ></tr>
            </table>
        </div>
        <button
            mat-button color="accent"
            mat-raised-button
            [disabled]="form.invalid"
            (click)="handleClickSave()">
            Save
        </button>
    </mat-card-content>
</mat-card>