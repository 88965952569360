import { Component, Input } from '@angular/core';
import { UserSummary } from '../../entity/UserSummary';

@Component({
  selector: 'app-user-short-info',
  templateUrl: './user-short-info.html',
  styleUrls: ['./user-short-info.sass']
})
export class UserShortInfoComponent {

  @Input()
  public isClickable = true;
  @Input()
  public user?: UserSummary;

}
