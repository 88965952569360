import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.html',
  styleUrls: ['./confirm-modal.sass']
})
export class ConfirmModalComponent {

  public error?: Error;

  constructor(private readonly dialogRef: MatDialogRef<ConfirmModalComponent>) { }

  public confirm(): void {
    this.dialogRef.close(true);
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

}




