import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { DiscountService } from '../../services/discount.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.html',
  styleUrls: ['./cart.sass']
})
export class CartComponent implements AfterViewInit, OnDestroy {

  public isViewForAdmin = false;
  public subscriptionOnDiscountTimer?: Subscription;

  constructor(
    public readonly discountService: DiscountService,
    public readonly cartService: CartService,
    private readonly userService: UserService,
  ) {
    this.isViewForAdmin = this.userService.isAdmin();
  }

  public ngAfterViewInit(): void {
    this.subscriptionOnDiscountTimer = this.discountService.onDiscountTimeUpdate(() => {
      this.cartService.updateTotalCartPrice();
    });
  }

  public ngOnDestroy(): void {
    this.subscriptionOnDiscountTimer?.unsubscribe();
  }

}

