import { EventEmitter, Injectable } from '@angular/core';
import { Feedback } from '../entity/Feedback';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class FeedbackService {

  public newFeedbackReceived: EventEmitter<unknown> = new EventEmitter<unknown>();

  constructor(private readonly httpClient: HttpClient) { }

  public getFeedback(size: number, page: number, sort: string): Observable<{ data: Feedback[], totalCount: number }> {
    const url = environment.API_URL.concat('/feedback');
    const params = new HttpParams()
      .set('size', size.toString(10))
      .set('page', page.toString(10))
      .set('sort', sort);

    return this.httpClient.get<{ data: Feedback[], totalCount: number }>(url, { headers: new HttpHeaders(), params });
  }

  public postFeedback(message: string): Observable<void> {
    const url = environment.API_URL.concat('/feedback');

    return this.httpClient.post<Feedback>(url, { message }, { headers: new HttpHeaders() }).pipe(map(() => this.newFeedbackReceived.emit()));
  }

}
