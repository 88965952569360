import { AuthGuard } from './services/auth.guard.service';
import { CartResolver } from './resolvers/cart.resolver';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DiscountsComponent } from './pages/discounts/discounts.component';
import { EmailsComponent } from './pages/emails/emails.component';
import { FeedbackComponent } from './pages/feedbacks/feedback.component';
import { HomeComponent } from './pages/home/home.component';
import { ManageProductComponent } from './pages/manage-product/manage-product.component';
import { NgModule } from '@angular/core';
import { OrdersComponent } from './components/orders/orders.component';
import { ProductsComponent } from './components/products/products.component';
import { RoleEnum } from './enums/RoleEnum';
import { RouterModule, Routes } from '@angular/router';
import { UserInfoResolver } from './resolvers/user-info.resolver';
import { UserTransactionsComponent } from './components/user-transactions/user-transactions.component';
import { UsersManagementComponent } from './components/users-management/users-management.component';
import { WalletComponent } from './pages/wallet/wallet.component';

const adminRoutes: Routes = [
  { path: 'dashboard', component: DashboardComponent, },
  { path: 'orders', component: OrdersComponent, },
  { path: 'products', component: ProductsComponent, },
  { path: 'discounts', component: DiscountsComponent, },
  { path: 'editProduct/:id', component: ManageProductComponent, },
  { path: 'users', component: UsersManagementComponent, },
  { path: 'feedbacks', component: FeedbackComponent, },
  { path: 'emails', component: EmailsComponent, },
  { path: 'transactions', component: UserTransactionsComponent, },
];

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: {
      user: UserInfoResolver,
      cart: CartResolver,
    },
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'wallet',
        component: WalletComponent,
      },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        data: { roles: [RoleEnum.ADMIN] },
        children: adminRoutes,
      },
    ]
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}
