import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { LoadingService } from '../loading/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl } from '@angular/forms';
import { UserCoinAudit } from '../../entity/UserCoinAudit';
import { UserManagementService } from '../../services/user-management.service';
import { UserSummary } from '../../entity/UserSummary';
import { throttle } from '../../decorators/throttle.decorator';
import { throttleDelayInMilliseconds } from 'src/app/constants/constants';

@Component({
  selector: 'app-user-transactions',
  templateUrl: './user-transactions.html',
  styleUrls: ['./user-transactions.sass']
})
export class UserTransactionsComponent implements AfterViewInit, OnInit {

  @Input()
  public isUserSelectorDisplayed = true;
  @ViewChild(MatPaginator)
  private readonly paginator!: MatPaginator;
  @ViewChild(MatSort)
  private readonly sort!: MatSort;

  public audits = new MatTableDataSource<UserCoinAudit>();
  public length = 100;
  public pageEvent!: PageEvent;
  public selectedUser = new UntypedFormControl();
  public users: UserSummary[] = [];

  public readonly pageSize = 10;
  public readonly pageSizeOptions = [5, 10, 25, 100];
  public readonly displayedColumns = ['createdAt', 'user', 'comment', 'before', 'diff', 'after', 'trend'];

  constructor(
    private readonly modalService: MatDialog,
    private readonly userManagementService: UserManagementService,
    private readonly loadingService: LoadingService,
  ) { }

  public ngOnInit(): void {
    if (this.isUserSelectorDisplayed) {
      this.loadingService.showSpinner();
      this.userManagementService
        .getUsersSummary('', 1000, 0, 'name,asc')
        .subscribe({
          next: (res) => this.users = res.data,
          complete: () => this.loadingService.hideSpinner(),
        });
    }

    this.fetchAudits();
  }

  public ngAfterViewInit(): void {
    this.audits.sort = this.sort;
    this.sort.sortChange.subscribe(() => this.fetchAudits());
  }

  @throttle(throttleDelayInMilliseconds)
  public fetchAudits(
    user: UserSummary = this.selectedUser.value,
    pageSize = this.pageEvent?.pageSize || 10,
    page = this.pageEvent?.pageIndex || 0,
    sort = this.sort ? `${this.sort.active},${this.sort.direction}` : 'updatedAt,desc'
  ): void {
    this.loadingService.showSpinner();

    if (this.isUserSelectorDisplayed) {
      this.userManagementService.getAuditForUser(user?.id, pageSize, page, sort).subscribe({
        next: (audits) => {
          this.length = audits.totalCount;
          this.audits.data = audits.data;
        },
        complete: () => this.loadingService.hideSpinner(),
      });
    } else {
      this.userManagementService.getAuditForCurrentUser(pageSize, page, sort).subscribe({
        next: (audits) => {
          this.length = audits.totalCount;
          this.audits.data = audits.data;
        },
        complete: () => this.loadingService.hideSpinner(),
      });
    }
  }

  public userChange(): void {
    if (this.pageEvent) {
      this.pageEvent.pageIndex = 0;
    }
    this.paginator.pageIndex = 0;
    this.fetchAudits();
  }

}

