<div style="margin-top: 16px">
  <div fxFlex="1 1 auto" fxLayoutAlign="start center" style="padding-left: 16px">
    <button mat-button mat-raised-button color="accent" (click)="openAddCoinsModal()">Add coins</button>
  </div>
  <mat-form-field>
    <mat-label>Search</mat-label>
    <input [(ngModel)]="searchField" matInput value="" #searchFieldElement>
  </mat-form-field>

  <mat-paginator (page)="pageEvent = $event; fetchUsers()"
                 [length]="length"
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize">
  </mat-paginator>
</div>


<table [dataSource]="users" class="mat-elevation-z8"
       mat-table
       matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let user"> {{user.name}} </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
    <td mat-cell *matCellDef="let user"> {{user.email}} </td>
  </ng-container>

  <ng-container matColumnDef="coin">
    <th mat-header-cell *matHeaderCellDef>Coins</th>
    <td mat-cell *matCellDef="let user">
      <div fxLayout="row">
        {{user.userCoin.silver}}
        <app-coin type="silver"></app-coin>
        {{user.userCoin.gold}}
        <app-coin type="gold"></app-coin>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef>Role</th>
    <td mat-cell *matCellDef="let user"> {{user.role}}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let user"> {{user.isActive ? 'Active' : 'Disabled'}}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let user">
      <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Product actions">
        <mat-icon color="accent">more_vert</mat-icon>
      </button>

      <mat-menu #menu>
        <button *ngIf="user.isActive" (click)="disableUser(user)" mat-menu-item>
          <mat-icon color="accent">block</mat-icon>
          <span>Disable user</span>
        </button>

        <button *ngIf="!user.isActive" (click)="enableUser(user)" mat-menu-item>
          <mat-icon (click)="enableUser(user)" color="accent">check_sircle</mat-icon>
          <span>Enable user</span>
        </button>

        <div [matTooltip]="user.role === 'ADMIN' ? 'User is already admin' : ''">
          <button [disabled]="user.role === 'ADMIN'" (click)="makeUserAdmin(user)" mat-menu-item>
            <mat-icon color="accent">manage_accounts</mat-icon>
            <span>Make user an admin</span>
          </button>
        </div>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<div *ngIf="!users.data.length && isLoaded"
     fxLayout="column" fxLayoutAlign="center center" style="margin-top: 36px">
  <h2>Can't find this user</h2>
  <img src="../../assets/sentiment_neutral.svg" alt="Neutral face" style="width: 80px;">
</div>
