// Borrowed from https://stackblitz.com/edit/throttle-decorator-vs-directive?file=src%2Fapp%2Fapp.throttle-hint-3-working-solution.decorator.ts
export function throttle(throttleMs = 2000): Function {
  let timeout: NodeJS.Timeout | null = null;

  return function(
    target: never,
    methodKey: string,
    descriptor: PropertyDescriptor
  ): void | PropertyDescriptor {
    const original = descriptor.value;

    descriptor.value = function(...args: never[]) {
      if (timeout) return;

      timeout = setTimeout(() => timeout = null, throttleMs);
      original.apply(this, args);
    };

    return descriptor;
  };
}
