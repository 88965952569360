import { Component } from '@angular/core';
import { LoadingService } from '../loading.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.sass'],
})
export class LoadingSpinnerComponent {

  constructor(public readonly loadingService: LoadingService) { }

}
