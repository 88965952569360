import { Component } from '@angular/core';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { EmailService } from '../../services/email.service';
import { LoadingService } from '../../components/loading/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.html',
  styleUrls: ['./emails.sass']
})
export class EmailsComponent {

  constructor(
    private readonly emailService: EmailService,
    private readonly snackBarService: MatSnackBar,
    private readonly modalService: MatDialog,
    private readonly loadingService: LoadingService,
  ) { }

  public sendNewProductsEmail(): void {
    const dialogRef = this.modalService.open(ConfirmModalComponent);

    dialogRef.afterClosed().subscribe((userConfirmAction) => {
      if (userConfirmAction) {
        this.loadingService.showSpinner();
        this.emailService.sendNewProductsEmail().subscribe({
          next: () => this.snackBarService.open('Emails sent', undefined, { duration: 5000 }),
          complete: () => this.loadingService.hideSpinner(),
        });
      }
    });
  }

  public sendNewDiscountEmail(): void {
    const dialogRef = this.modalService.open(ConfirmModalComponent);

    dialogRef.afterClosed().subscribe((userConfirmAction) => {
      if (userConfirmAction) {
        this.loadingService.showSpinner();
        this.emailService.sendNewDiscountEmail().subscribe({
          next: () => this.snackBarService.open('Emails sent', undefined, { duration: 5000 }),
          complete: () => this.loadingService.hideSpinner(),
        });
      }
    });
  }

}

