import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class EmailService {

  constructor(private readonly httpClient: HttpClient) { }

  public sendNewProductsEmail(): Observable<void> {
    const url = environment.API_URL.concat('/email/products');

    return this.httpClient.post<void>(url, {}, { headers: new HttpHeaders() });
  }

  public sendNewDiscountEmail(): Observable<void> {
    const url = environment.API_URL.concat('/email/discounts');

    return this.httpClient.post<void>(url, {}, { headers: new HttpHeaders() });
  }

}
