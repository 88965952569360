import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ErrorModalComponent } from '../../modals/error-modal/error-modal.component';
import { Feedback } from '../../entity/Feedback';
import { FeedbackService } from '../../services/feedback.service';
import { LoadingService } from '../../components/loading/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.html',
  styleUrls: ['./feedback.sass']
})
export class FeedbackComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort)
  private readonly sort!: MatSort;

  public length = 100;
  public pageEvent!: PageEvent;
  public feedbacks = new MatTableDataSource<Feedback>();

  public readonly displayedColumns = ['message', 'createdBy', 'createdAt'];
  public readonly pageSize = 10;
  public readonly pageSizeOptions = [5, 10, 25, 100];

  constructor(
    private readonly feedbackService: FeedbackService,
    private readonly matDialog: MatDialog,
    private readonly loadingService: LoadingService,
  ) {
    this.feedbackService.newFeedbackReceived.subscribe(() => this.fetchData());
  }

  public ngAfterViewInit(): void {
    this.feedbacks.sort = this.sort;
    this.sort.sortChange.subscribe(() => this.fetchData());
  }

  public ngOnInit(): void {
    this.fetchData();
  }

  public fetchData(
    pageSize = this.pageEvent?.pageSize || 5,
    page = this.pageEvent?.pageIndex || 0,
    sort = this.sort ? `${this.sort.active},${this.sort.direction}`: 'updatedAt,desc'
  ): void {
    this.loadingService.showSpinner();
    this.feedbackService.getFeedback(pageSize, page, sort).subscribe({
      next: (feedbacks) => {
        this.length = feedbacks.totalCount;
        this.feedbacks.data = feedbacks.data;
      },
      error: (error) => this.matDialog.open(ErrorModalComponent, { data: error }),
      complete: () => this.loadingService.hideSpinner(),
    });
  }

}

