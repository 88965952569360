import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../entity/User';
import { UserCoinAudit } from '../entity/UserCoinAudit';
import { UserSummary } from '../entity/UserSummary';
import { environment } from '../../environments/environment';

@Injectable()
export class UserManagementService {

  constructor(private readonly httpClient: HttpClient) { }

  public getUsers(query: string, size: number, page: number, sort: string): Observable<{ data: User[], totalCount: number }> {
    const url = environment.API_URL.concat('/user');
    const params = new HttpParams()
      .set('query', query)
      .set('size', size.toString(10))
      .set('page', page.toString(10))
      .set('sort', sort);

    return this.httpClient.get<{ data: User[], totalCount: number }>(url, { headers: new HttpHeaders(), params });
  }

  public getUsersSummary(query: string, size: number, page: number, sort: string): Observable<{ data: UserSummary[], totalCount: number }> {
    const url = environment.API_URL.concat('/user/summary');
    const params = new HttpParams()
      .set('query', query)
      .set('size', size.toString(10))
      .set('page', page.toString(10))
      .set('sort', sort);

    return this.httpClient.get<{ data: UserSummary[], totalCount: number }>(url, { headers: new HttpHeaders(), params });
  }

  public getCurrentUser(): Observable<User> {
    const url = environment.API_URL.concat('/user/info');

    return this.httpClient.get<User>(url, { headers: new HttpHeaders() });
  }

  public enableUser(id: string): Observable<User> {
    const url = environment.API_URL.concat(`/user/${id}/enable`);

    return this.httpClient.put<User>(url, { headers: new HttpHeaders() });
  }

  public disableUser(id: string): Observable<User> {
    const url = environment.API_URL.concat(`/user/${id}/disable`);

    return this.httpClient.put<User>(url, { headers: new HttpHeaders() });
  }


  public getAuditForCurrentUser(size: number, page: number, sort: string): Observable<{ data: UserCoinAudit[], totalCount: number }> {
    const url = environment.API_URL.concat('/user/coin/audit');
    const params = new HttpParams()
      .set('size', size.toString(10))
      .set('page', page.toString(10))
      .set('sort', sort);

    return this.httpClient.get<{ data: UserCoinAudit[], totalCount: number }>(url, { headers: new HttpHeaders(), params });
  }

  public getAuditForUser(id: string, size: number, page: number, sort: string): Observable<{ data: UserCoinAudit[], totalCount: number }> {
    const url = environment.API_URL.concat('/transaction/');
    let params = null;

    if (id) {
      params = new HttpParams()
        .set('size', size.toString(10))
        .set('page', page.toString(10))
        .set('sort', sort)
        .set('id', id);
    } else {
      params = new HttpParams()
        .set('size', size.toString(10))
        .set('page', page.toString(10))
        .set('sort', sort);
    } // params.set and params.add doesn't work

    return this.httpClient.get<{ data: UserCoinAudit[], totalCount: number }>(url, { headers: new HttpHeaders(), params });
  }

  public makeUserAdmin(id: string): Observable<User> {
    const url = environment.API_URL.concat('/user/role');

    return this.httpClient.patch<User>(url, { userId: id, role: 'ADMIN' }, { headers: new HttpHeaders() });
  }

}
