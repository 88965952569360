<div>
  <div fxFlex="1 1 auto"></div>
  <mat-paginator (page)="pageEvent = $event; fetchData()"
                 [length]="length"
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize">
  </mat-paginator>
</div>

<table [dataSource]="feedbacks"
       class="mat-elevation-z8"
       mat-table
       matSort
       matSortActive="createdAt"
       matSortDirection="desc"
       matSortDisableClear
>
  <ng-container matColumnDef="message">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Message</th>
    <td mat-cell *matCellDef="let row">
      {{row.message}}
    </td>
  </ng-container>

  <ng-container matColumnDef="createdBy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Created by</th>
    <td mat-cell *matCellDef="let row">
      {{row.createdBy}}
    </td>

  </ng-container>
  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
    <td mat-cell *matCellDef="let row">
      {{row.createdAt | date}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
