import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren
} from '@angular/core';
import { FocusKeyManager } from '@angular/cdk/a11y';
import { ReplaySubject, Subject } from 'rxjs';
import { SelectableUser } from '../../entity/SelectableUser';
import { UntypedFormControl } from '@angular/forms';
import { UserSelectOptionComponent } from './user-select-option/user-select-option.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.sass'],
  animations: [
    trigger('transformPanel', [
      state('void', style({
        transform: 'scaleY(0.8)',
        minWidth: '100%',
        opacity: 0
      })),
      state('showing', style({
        opacity: 1,
        minWidth: 'calc(100% + 32px)',
        transform: 'scaleY(1)'
      })),
      state('showing-multiple', style({
        opacity: 1,
        minWidth: 'calc(100% + 64px)',
        transform: 'scaleY(1)'
      })),
      transition('void => *', animate('120ms cubic-bezier(0, 0, 0.2, 1)')),
      transition('* => void', animate('100ms 25ms linear', style({ opacity: 0 })))
    ])
  ],
})
export class UserSelectComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input()
  public users!: SelectableUser[];
  @Input()
  public isMultipleSelectionEnabled = false;
  @Input()
  public label!: string;
  @Output()
  public selectedUserIds = new EventEmitter<string[]>();

  public selectedUsers: SelectableUser[] = [];
  public selectedUser!: SelectableUser;
  public filteredUsers = new ReplaySubject<SelectableUser[]>(1);
  public isAllSelected = false;
  public isOptionsOpened = false;
  // Filter property used to hide select all option when filter value typed
  public filter = '';
  public inputControl = new UntypedFormControl('', [() => {
    if (this.isMultipleSelectionEnabled) {
      return this.selectedUsers.length ? null : { userSelectionRequired: 'no user selected' };
    } else {
      return this.selectedUser ? null : { userSelectionRequired: 'no user selected' };
    }
  }]);

  private selectedUsersInputValue = '';
  private readonly onDestroy = new Subject();

  @ViewChild('input')
  public input!: ElementRef;

  @ViewChildren(UserSelectOptionComponent)
  public items!: QueryList<UserSelectOptionComponent>;
  private keyManager!: FocusKeyManager<UserSelectOptionComponent>;

  @HostListener('keydown', ['$event'])
  public manage(event: KeyboardEvent): void {
    this.keyManager.onKeydown(event);

    const activeItem = this.keyManager.activeItem;

    if (event.key === 'Enter') {
      if (activeItem?.isSelectAllEnabled) {
        this.toggleAll();
      } else if (activeItem) {
        this.toggle(activeItem.user);
      }
    }

    if (event.key.match(/^\w$/)) {
      this.input.nativeElement.focus();
    }

    if (event.key === 'Tab') {
      this.closeOptions();
    }
  }

  public ngAfterViewInit(): void {
    this.keyManager = new FocusKeyManager<UserSelectOptionComponent>(this.items).withWrap();
  }

  public ngOnInit(): void {
    this.users.map(user => user.selected = false);
    this.inputControl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((value) => {
      this.filter = value;
      this.filterUsers();
    });
  }

  public ngOnChanges(): void {
    this.selectedUsers = this.users.filter((user) => !!user.selected);
    this.setSelectedUsersInputValue();
  }

  public ngOnDestroy(): void {
    this.filteredUsers.unsubscribe();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public filterUsers(): void {
    if (this.inputControl.value === '') {
      this.filteredUsers.next(this.users);

      return;
    }

    if (this.users?.length) {
      this.filteredUsers.next(this.users.filter(user => user.name.toLowerCase().includes(this.inputControl.value.toLowerCase())));
    }
  }

  public selectOne(selectedUser: SelectableUser): void {
    if (this.selectedUser?.id !== selectedUser.id) {
      this.selectedUserIds.emit([selectedUser.id]);
      this.selectedUser = selectedUser;
      this.selectedUsersInputValue = selectedUser.name;
    }

    this.onInputFocusOut();
    this.closeOptions();
  }

  public toggle(selectedUser: SelectableUser): void {
    if (!this.isMultipleSelectionEnabled) {
      this.selectOne(selectedUser);

      return;
    }

    selectedUser.selected = !selectedUser.selected;

    if (selectedUser.selected) {
      this.selectedUsers.push(selectedUser);
    } else {
      this.selectedUsers = this.selectedUsers.filter(user => user.selected);
    }

    this.setSelectedUsersInputValue();
  }

  public openOptions(): void {
    this.isOptionsOpened = true;
    this.inputControl.setValue('');
    this.filteredUsers.next(this.users);
  }

  public closeOptions(): void {
    this.isOptionsOpened = false;
  }

  public toggleAll(): void {
    this.isAllSelected = !this.isAllSelected;
    this.selectedUsers = this.isAllSelected ? this.users.slice() : [];
    this.users.map(user => user.selected = this.isAllSelected);
    this.setSelectedUsersInputValue();
  }

  public onInputFocusOut(): void {
    // {emitEvent: false} is used to not emit event when setValue method executed,
    // because inputControl.valueChanges is used only to filter users. So, when setValue performs
    // input.valueChanges mustn't be called (only when user enters filter value manually).
    this.inputControl.setValue(this.selectedUsersInputValue, { emitEvent: false });
  }

  private setSelectedUsersInputValue(): void {
    if (this.selectedUsers.length > 0) {
      this.selectedUsersInputValue =
        this.selectedUsers.reduce((accumulator: string, currentUser, index) => {
          if (index === 0) {
            return accumulator.concat(currentUser.name);
          } else if (index <= 2) {
            return accumulator.concat(', ').concat(currentUser.name);
          } else if (index === 3) {
            return accumulator.concat(' and others');
          }

          return accumulator.concat('');
        }, '');
    } else {
      this.selectedUsersInputValue = '';
    }

    this.onInputFocusOut();
    this.selectedUserIds.emit(this.selectedUsers.map(user => user.id));
  }

}
