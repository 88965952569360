<section style="padding: 16px">

  <div>
    <div *ngIf="isUserSelectorDisplayed" fxFlex="1 1 auto" style="margin: 0">
      <mat-form-field color="accent">
        <mat-label>User</mat-label>
        <mat-select [formControl]="selectedUser" (ngModelChange)="userChange()">
          <mat-option>All transactions</mat-option>
          <mat-option *ngFor="let user of users" [value]="user">
            <app-user-short-info [user]="user"></app-user-short-info>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div [hidden]="!audits.data.length">
      <h2 *ngIf="!isUserSelectorDisplayed"
          fxFlex="1 1 auto"
          fxLayoutAlign="center center"
          style="margin: 0">
        Your Transactions
      </h2>
      <mat-paginator
                     (page)="pageEvent = $event; fetchAudits()"
                     [length]="length"
                     [pageSizeOptions]="pageSizeOptions"
                     [pageSize]="pageSize">
      </mat-paginator>
    </div>
  </div>

  <br>

  <table [hidden]="!audits.data.length"
         [dataSource]="audits"
         class="mat-elevation-z8"
         mat-table
         matSort
         matSortActive="createdAt"
         matSortDirection="desc"
         matSortDisableClear>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 90px">Date</th>
      <td mat-cell *matCellDef="let audit" style="width: 90px; white-space: nowrap;">
        {{audit.createdAt | date}}
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef style="padding-left: 16px; width: 5%;">User</th>
      <td mat-cell *matCellDef="let audit" style="width: 5%; white-space: nowrap; padding: 0px 16px">
        {{audit.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Comment</th>
      <td mat-cell *matCellDef="let audit" style="width: 90%">
        {{audit.comment}}
      </td>
    </ng-container>

    <ng-container matColumnDef="before">
      <th mat-header-cell *matHeaderCellDef class="small-column small-column_centered">Before</th>
      <td mat-cell *matCellDef="let audit" class="small-column">
        <div fxLayoutAlign="end center" style="white-space: nowrap">
          {{audit.total - audit.value}}
          <app-coin [type]="audit.type.toLowerCase()"></app-coin>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="diff">
      <th mat-header-cell *matHeaderCellDef class="small-column small-column_centered">Amount</th>
      <td mat-cell *matCellDef="let audit" class="small-column">
        <div *ngIf="audit.value > 0" fxLayoutAlign="end center" style="white-space: nowrap">
          +{{audit.value}}
          <app-coin [type]="audit.type.toLowerCase()"></app-coin>
        </div>
        <div *ngIf="audit.value <= 0" fxLayoutAlign="end center" style="white-space: nowrap">
          {{audit.value}}
          <app-coin [type]="audit.type.toLowerCase()"></app-coin>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="after">
      <th mat-header-cell *matHeaderCellDef class="small-column small-column_centered">After</th>
      <td mat-cell *matCellDef="let audit" class="small-column">
        <div fxLayoutAlign="end center" style="white-space: nowrap">
          {{audit.total}}
          <app-coin [type]="audit.type.toLowerCase()"></app-coin>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="trend">
      <th mat-header-cell *matHeaderCellDef class="small-column ">Trend</th>
      <td mat-cell *matCellDef="let audit" class="small-column">
        <div *ngIf="audit.value > 0" fxLayoutAlign="center center" style="white-space: nowrap">
          <mat-icon class="stonks">trending_up</mat-icon>
        </div>
        <div *ngIf="audit.value <= 0" fxLayoutAlign="center center" style="white-space: nowrap">
          <mat-icon class="not_stonks">trending_down</mat-icon>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</section>
