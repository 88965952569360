<h1 mat-dialog-title style="text-align: center">Send Feedback</h1>

<div mat-dialog-content fxLayout="column">
  <mat-form-field class="example-full-width" color="accent">
    <mat-label>Comment</mat-label>
    <textarea matInput
              placeholder="Ex. It makes me feel..."
              [(ngModel)]="message"
              maxlength="255"
              ></textarea>
  </mat-form-field>
</div>

<mat-dialog-actions align="center">
  <button mat-button mat-raised-button mat-dialog-close color="accent">Cancel</button>
  <button
    mat-button
    mat-raised-button
    mat-dialog-close
    color="accent"
    [disabled]="!message.trim().length || message.trim().length > 254"
    (click)="sendFeedback()"
  >
    Send
  </button>
</mat-dialog-actions>

